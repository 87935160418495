import { pagePaths } from 'config/pages';

export const USER_SETTINGS_INPUTS = {
  FIRSTNAME: 'FIRSTNAME',
  LASTNAME: 'LASTNAME',
  USERNAME: 'USERNAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
  ROLE: 'ROLE',
  BRANCHES: 'BRANCHES',
  PASSWORD: 'PASSWORD'
};

export const USER_SETTINGS_BREADCRUMBS = [
  {
    label: 'dashboard_button.dashboard',
    pathname: pagePaths.settings
  },
  {
    label: 'settings_button.settings',
    pathname: pagePaths.settings
  },
  {
    label: 'settings.users'
  }
];

export const SUPPORTED_LANG = ['en', 'th', 'vi', 'ja', 'zh-TW'];
