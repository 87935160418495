import { FC } from 'react';

import { Add } from 'assets/icons';
import { AddTeamMember, Button, UserList } from 'components';
import { If } from 'components/Generics';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { Participant } from 'types/Inbox.types';
import { isDataExist, translate } from 'utils';
import { getParticipantCount } from 'utils/common';

interface Props {
  participantsList: Participant[];
  isFromTicketDetails?: boolean;
  showAddButton?: boolean;
  setParticipantsList?: (participants: Participant[]) => void;
  isAddParticipantPopupOpen?: boolean;
  setIsAddParticipantPopupOpen: (value: boolean) => void;
  hideHeader?: boolean;
  isTPCouncil: boolean;
}

const ParticipantsList: FC<Props> = ({
  participantsList,
  isFromTicketDetails = false,
  showAddButton = true,
  setParticipantsList,
  isAddParticipantPopupOpen,
  setIsAddParticipantPopupOpen,
  hideHeader = false,
  isTPCouncil
}) => {
  const handleSegmentTrack = useSegmentTrackChat();
  const { trackData, trackContextData, trackPatientData } = useTrackData();
  const handleAddClick = () => {
    setIsAddParticipantPopupOpen(true);
    const {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission
    } = trackData;
    const {
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type
    } = trackContextData;
    handleSegmentTrack(CHAT_TRACK_EVENTS.PARTICIPANT_ADD_INITIATED, {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission,
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type,
      ...trackPatientData,
      no_of_participants: getParticipantCount(participantsList),
      is_chat_created: isFromTicketDetails ? 'yes' : 'no'
    });
  };

  return (
    <div className='p-4'>
      <If condition={!hideHeader}>
        <div className='flex justify-between'>
          <h4 className='text-sm font-semibold w-full'>
            {translate('descriptionText.participants')}
          </h4>

          {isDataExist(participantsList) && showAddButton && !isTPCouncil && (
            <Button
              className='bg-PRIMARY w-5 h-5  mr-1'
              onClick={handleAddClick}
            >
              <p className='flex justify-center items-center'>
                <Add className='text-WHITE' />
              </p>
            </Button>
          )}
        </div>
      </If>
      <div className='md:max-h-72 xxs:max-h-44 overflow-y-scroll'>
        <UserList
          participantsList={participantsList}
          isFromTicketDetails={isFromTicketDetails}
          setParticipantsList={setParticipantsList}
          isTPCouncil={isTPCouncil}
        />
      </div>
      {isAddParticipantPopupOpen && (
        <AddTeamMember
          participantsList={participantsList}
          isAddParticipantPopupOpen={isAddParticipantPopupOpen}
          isFromTicketDetails={isFromTicketDetails}
          setParticipantsList={setParticipantsList}
          onClose={() => {
            setIsAddParticipantPopupOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default ParticipantsList;
