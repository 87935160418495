import { FC, useState } from 'react';

import { Modal } from 'react-responsive-modal';

import { ModalClose, PdfIcon, VideoIcon } from 'assets/icons';
import ProgressiveImage from 'components/ProgressiveImage/ProgressiveImage';

type ThumbnailProps = {
  type?: 'img' | 'file';
  file: string;
  thumbnail?: string;
  height?: string;
  fileId?: string;
  classification?: string;
  name?: string;
  onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void;
  isVideoFile?: boolean;
};

const Thumbnail: FC<ThumbnailProps> = ({
  type = 'img',
  file = '',
  thumbnail = '',
  fileId = '',
  height = 'h-28.5 md:h-40',
  classification = '',
  name,
  onDragStart,
  isVideoFile
}) => {
  const [isEnlargedView, toggleEnlargedView] = useState(false);

  if (type === 'img') {
    return (
      <>
        <button type='button' className={`${height} w-full rounded`}>
          <div
            className='object-cover rounded h-full w-full relative'
            draggable={fileId ? 'true' : 'false'}
            onDragStart={onDragStart}
          >
            <ProgressiveImage
              placeholder={thumbnail}
              src={file}
              alt='Uploaded File'
              className='object-cover rounded h-full w-full'
              draggable='false'
            />
            {classification && (
              <div className='absolute  left-1/2 -translate-x-1/2 bottom-2 bg-DEFAULT_TEXT rounded-3xl text-white px-3 py-1 md:text-xs font-normal w-max xxs:text-8px'>
                {classification}
              </div>
            )}
          </div>
        </button>
        <Modal
          open={isEnlargedView}
          onClose={() => toggleEnlargedView(false)}
          center
          classNames={{
            modal: 'bg-transparent shadow-none p-0',
            closeButton: 'top-4 right-4 cursor-pointer'
          }}
          closeIcon={<ModalClose />}
        >
          <a
            href={file}
            target='_blank'
            rel='noreferrer'
            download={name}
            className='relative max-w-896px'
            style={{ cursor: 'zoom-in' }}
          >
            <img
              src={file}
              alt='Uploaded File'
              className='object-cover rounded-8px  max-w-full'
            />
          </a>
        </Modal>
      </>
    );
  }
  return (
    <div
      draggable='true'
      className={`${height} w-full rounded relative`}
      onDragStart={onDragStart}
    >
      <a
        href={file}
        title='pdf'
        target='_blank'
        rel='noreferrer'
        download={name}
        className={`${height} w-full flex justify-center items-center bg-GRAY rounded`}
        draggable='false'
      >
        {isVideoFile ? (
          <VideoIcon
            data-testid='pdf-icon'
            title='pdf-icon'
            className='w-8 h-8'
          />
        ) : (
          <PdfIcon
            data-testid='pdf-icon'
            title='pdf-icon'
            className='w-8 h-8'
          />
        )}
        {classification && (
          <div className='absolute  left-1/2 -translate-x-1/2 bottom-2 bg-DEFAULT_TEXT rounded-3xl text-white px-3 py-1 text-xs font-normal w-max xxs:hidden md:block'>
            {classification}
          </div>
        )}
      </a>
    </div>
  );
};

export default Thumbnail;
