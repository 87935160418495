import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult
} from '@apollo/client';

import { Classification, FilesData } from './Files.types';
import { WhiteningPrescriptionValidityEnum } from './PatientList.types';
import { QueryCallbackFunctions } from './request';
import { RetainerSchedule } from './RetainerPurchase.types';
import { Option } from './select';
import { TPVFormTypes, TpvApiInput } from './TPVForm.types';

export type EnumResponse = {
  value: string;
  label: string;
};
export interface TreatmentSpecificationsType {
  value?: string;
  label: string;
  hide?: boolean;
}

export type TreatmentSpecificationOptions = {
  getAttachementOptions: EnumResponse[];
  getCrowdingTreatmentOptions: EnumResponse[];
  getIPRLocations: EnumResponse[];
};

export type TreatmentPlanOptions = {
  getAllTreatmentTypes: EnumResponse[];
};

export interface UseGetPriorTreatmentOptions {
  (config?: QueryHookOptions): QueryResult<{
    getPriorTreatmentsList: EnumResponse[];
  }>;
}

export interface UseGetTreatmentPlanOptions {
  (config?: QueryHookOptions): QueryResult<TreatmentPlanOptions>;
}

export interface UseGetTreatmentSpecificationOptions {
  (config?: QueryHookOptions): QueryResult<TreatmentSpecificationOptions>;
}

export interface PriorTreatment {
  completed?: boolean;
  treatments?: string[];
  canPlanningProceed?: boolean;
}

interface Cadence {
  hours: number;
  wearTimePrescribed: boolean;
  schedule: RetainerSchedule;
  additionalInstructions: string;
  days: number;
}

interface WhiteningInfo {
  isValidForRepurchase: boolean;
  isWhiteningEligible: boolean;
  validFor: WhiteningPrescriptionValidityEnum;
}
interface RetainerSetInfo {
  upper: number;
  lower: number;
  type: string;
}

export interface TreatmentSpecifications {
  iprLocation?: string;
  teethToExtract?: string;
  treatmentPlanTypes?: string[] | TPVFormTypes[];
  attachmentRequired?: string;
  zenplusInstructions?: string;
  crowdingResolvement?: string;
  treatmentPlanInstructions?: string;
  useToothImpressions?: boolean;
  tpv?: TpvApiInput;
  version?: string;
  cadence?: Cadence;
  whiteningInfo?: WhiteningInfo;
  retainerSetInfo?: RetainerSetInfo;
  isRetainerEligible?: boolean;
  retainerRejectionReason?: string;
  whiteningRejectionReason?: string;
  whiteningRejectionReasonDescription?: string;
  retainerRejectionReasonDescription?: string;
}

export interface TreatmentPlanning {
  priorTreatment: PriorTreatment;
  treatmentSpecifications: TreatmentSpecifications;
  prescriptionFirstFilledAt?: string;
}

export type TeethsArray = {
  rightTop: string[];
  rightBottom: string[];
  leftTop: string[];
  leftBottom: string[];
};

export type PriorTreatmentNeededType = {
  treatments?: string[];
  completed?: boolean;
  canPlanningProceed?: boolean;
};

export type TeethExtraction = {
  rightTop: Option[];
  rightBottom: Option[];
  leftTop: Option[];
  leftBottom: Option[];
};

export type TreatmentSpecType = {
  treatmentSpecAttachments?: string;
  iprLocation?: string;
  crowdingResolvement?: string;
  teethToExtract?: TeethExtraction;
  treatmentPlanTypes?: string;
  treatmentPlanInstructions?: string;
  zenplusInstructions?: string;
  teethExtractionInstructions?: string;
};

export enum TreatmentDocumentType {
  SCAN = 'SCAN',
  NEW_SCAN = 'NEW_SCAN',
  X_RAY = 'X_RAY',
  PHOTO = 'PHOTO',
  CONSENT_FORM = 'CONSENT_FORM',
  PRE_ASSESSMENT_PHOTO = 'PRE_ASSESSMENT_PHOTO'
}

export type TreatmentDocumentInput = {
  name: string;
  key?: string;
  classification?: Classification | null;
  sourceType: string;
  resubmissionStatus?: string;
  documentType?: TreatmentDocumentType;
};

export type TreatmentDocumentUploadUrlRes = Record<
  string,
  {
    url: string;
    name: string;
    key: string;
    extension: string;
  }[]
>;

export type TreatmentDocumentUploadInput = {
  treatmentId: string;
  documents: TreatmentDocumentInput[];
};

export type TreatmentDocumentRes = Record<
  string,
  { id: string; url: string; name: string }[]
>;

type TreatmentDocumentDeleteInput = {
  treatmentId: string;
  documentId: string;
};

export type TreatmentDocument = {
  documentId: string | number;
  classification: string | null;
  documentType?: TreatmentDocumentType;
};
export type TreatmentDocumentsUpdateInput = {
  treatmentId?: string;
  referralId?: string;
  input: TreatmentDocument[];
};

export interface DeleteTreatmentDocument {
  (
    config?: MutationHookOptions<
      { id: string },
      TreatmentDocumentDeleteInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    { id: string },
    TreatmentDocumentDeleteInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface DeleteTreatmentPlanDocument {
  (
    config?: MutationHookOptions<
      { id: string },
      TreatmentDocumentDeleteInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    { id: string },
    TreatmentDocumentDeleteInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface UpdateTreatmentDocument {
  (
    config?: MutationHookOptions<
      TreatmentDocumentsUpdateInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    TreatmentDocumentsUpdateInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export type RejectionFormData = {
  reOpenTpData: any;
  reOpenVersionData: any;
  reOpenTpvId: string;
  rejectionReason: Option;
  newRejectionReason: Option;
  resimulationInstructions: string;
  translatedResimulationInstructions?: string;
};
export type nextTreatment = {
  Tid: string;
  PendingFor: number;
  Name: string;
  PatientId: string;
};

export type TreatmentPlanVersionActionsType = {
  comments: string;
};

export type RetainerFormType = {
  scan: FilesData[];
};

export type RejectTPVFormType = {
  rejectionReason: Option[];
  showTreatingDoc: boolean;
  resimOrTpv: boolean;
};
export const midlineShiftsMapper: Record<string, string> = {
  UPPER_RIGHT: "Shift Upper - To patient's right",
  LOWER_RIGHT: "Shift Lower - To patient's right",
  UPPER_LEFT: "Shift Upper - To patient's left",
  LOWER_LEFT: "Shift Lower - To patient's left"
};
export const midlineMapper: Record<string, string> = {
  MAINTAIN: 'Maintain',
  SHOW_RESULTING: 'Show resulting',
  IMPROVE: 'Improve'
};
export const overBiteValueMapper: Record<string, string> = {
  SHOW_RESULTING: 'show.resulting',
  DEEP_BITE_INTRUDE: 'correct.deep.bite',
  OPEN_BITE_EXTRUDE: 'correct.open.bite'
};
