import { ERROR_CODE } from 'constants/errorCode';
import { TREATMENT_STAGES } from 'constants/patientList';
import { PATIENT_LIST_FILTERS } from 'types/PatientList.types';

import { TRACK_EVENTS } from './segment';

export const TREATMENT_STAGES_TRANSLATION_MAP: { [key: string]: string } = {
  ALL_ALIGNERS: 'tab.all_aligners',
  PENDING_PRESCRIPTION: 'pending_prescriptions.pending_prescriptions',
  IN_TREATMENT: 'status.in_treatment',
  TREATMENT_PLAN_REVIEW: 'chips_label.treatment_plan_review',
  END_OF_TREATMENT_REVIEW: 'end-of-treatment_reviews.end-of-treatment_reviews',
  PRE_ASSESSMENT: 'chips_label.pre-assessment',
  [TREATMENT_STAGES.INITIAL_TPV]: 'Initial TPV',
  [TREATMENT_STAGES.RESIM_REQUESTS]: 'Resim TPV',
  [TREATMENT_STAGES.TPV_REVIEW]: 'TPV Review',
  PRESCRIPTION: 'treatmentstage.prescription',
  PURCHASE: 'treatmentstage.purchase',
  PRODUCTION_AND_DELIVERY: 'treatmentstage.production_and_delivery',
  TREATMENT: 'treatmentstage.treatment'
};

export const EVENT_STATUS_TRANSLATION_MAP: { [key: string]: string } = {
  NO_SHOW: 'calendar.no_show',
  SHOW_UP: 'calendar.showed_up',
  RESCHEDULED: 'calendar.rescheduled',
  BOOKED: 'calendar.booked',
  CANCELLED: 'calendar.cancelled'
};

export const FILTER_CHIPS_TRANSLATION_MAP: { [index: string]: string } = {
  [PATIENT_LIST_FILTERS.REFERRAL]: 'referrals.descriptive',
  [PATIENT_LIST_FILTERS.TYPE]:
    'chips_label_(non_interactive).zenyumclear_zenyumclear_plus',
  [PATIENT_LIST_FILTERS.PLAN_TYPE]:
    'chips_label_(non_interactive).zenyumclear_zenyumclear_plus',
  [PATIENT_LIST_FILTERS.STATUS]: 'status.patient_status',
  [PATIENT_LIST_FILTERS.BRANCHES]: 'clinic_branch.clinic_branch',
  [PATIENT_LIST_FILTERS.DOCTOR]: 'doctor.doctor',
  [PATIENT_LIST_FILTERS.COUNCIL]: 'Council',
  [PATIENT_LIST_FILTERS.COUNTRY]: 'country.country'
};

export const ROLES_TRANSLATION_MAPPER: Record<string, string> = {
  CHAT_OWNER: 'chat_owner.chat_owner',
  CHAT_INITIATOR: 'chat_initiator.chat_initiator',
  CHAT_OBSERVER: 'chat_observer.chat_observer',
  CHAT_MEMBER: 'chat_member.chat_member',
  NURSE: 'nurse.nurse',
  EXTERNAL_DOCTOR: 'doctor.doctor',
  PATIENT: 'patient.patient',
  CUSTOMER_CARE: 'CC.CC',
  SALES_OPERATIONS: 'user.sales_operations',
  CASE_OPERATIONS: 'caseops.caseops',
  CASE_OPS_ADMIN: 'user.case_ops_admin',
  CUSTOMER_SUCCESS: 'CS.CS',
  EDUCATION_DOCTOR: 'education_doctor.education_doctor',
  ZENYUM_COUNCIL_ADMIN: 'zenyum_council.zenyum_council_admin',
  PARTNERSHIP_MANAGER: 'partnership_manager.partnership_manager',
  PRE_ASSESSMENT_LEAD: 'zenyum_council.pre_assessment_lead',
  PRE_ASSESSMENT_DOCTOR: 'zenyum_council.pre_assessment_doctor',
  TREATMENT_PLANNING_DOCTOR: 'zenyum_council.treatment_planning_doctor',
  ADMIN: 'user.admin',
  TREATMENT_PLANNING_LEAD: 'zenyum_council.treatment_planning_lead',
  OPERATIONS_DENTISTRY: 'zenyum_council.ops_dentistry',
  AFTERSALES_DOCTOR: 'zenyum_council.aftersales_doctor',
  CLINIC_ADMIN: 'clinic_admin.clinic_admin',
  OTHERS: 'prescriptions.others',
  CLINICAL_TRAINER: 'role.clinical_trainer',
  CLINICAL_TRAINER_HQ: 'role.clinical_trainer_HQ',
  SUPER_ADMIN: 'user.super_admin',
  INTERNAL_DOCTOR: 'Internal doctor',
  TREATING_DOCTOR: 'Treating doctor',
  CS_ADMIN: 'user.cs_admin',
  CC_ADMIN: 'user.cc_admin',
  PATIENT_CARE_LEAD: 'user.patient_care_lead',
  TP_COUNCIL: 'user.tp_council'
};

export const TREATMENT_ACTION_MAP: { [key: string]: string } = {
  PENDING_PATIENT_REVIEW:
    'approve_for_patient_review.approve_for_patient_review',
  REQUEST_NEW_VERSION: 'request_new_version.request_new_version',
  DISCUSSION_REQUIRED: 'tpv.submit_an_enquiry',
  REJECTED: 'tpv.submit_an_enquiry',
  CASE_WITH_INT_DOC: 'tpv.submit_an_enquiry'
};

export const TREATMENT_ACTION_INPUT_MAP: { [key: string]: string } = {
  REQUEST_NEW_VERSION: 'tpv.comments:',
  DISCUSSION_REQUIRED: 'tpv.enquiry',
  REJECTED: 'tpv.enquiry',
  PENDING_PATIENT_REVIEW: 'tpv.feedback_(if_any)',
  CASE_WITH_INT_DOC: 'tpv.enquiry'
};

export const EVENTPLANMAPPER: Record<string, string> = {
  REQUEST_NEW_VERSION: TRACK_EVENTS?.TREATMENT_PLAN_REQUEST_NEW_VERSION,
  DISCUSSION_REQUIRED: TRACK_EVENTS?.TREATMENT_PLAN_VERSION_ENQUIRED,
  PENDING_PATIENT_REVIEW: TRACK_EVENTS?.TREATMENT_PLAN_VERSION_APPROVED
};

export const EVENTDOCTORCOMMENTMAPPER: Record<string, string> = {
  REQUEST_NEW_VERSION: 'doctor_notes',
  DISCUSSION_REQUIRED: 'doctor_enquiry',
  PENDING_PATIENT_REVIEW: 'doctor_feedback'
};

export const EVENT_TYPE_TRANSLATION_MAP: Record<string, string> = {
  CONSULTATION: 'CONSULTATION',
  FOLLOW_UP: 'event_type.follow_up',
  FITTING: 'after_sales_appointment_type.fitting',
  EXTRACTION: 'event_type.extraction',
  IPR: 'after_sales_appointment_type.ipr',
  SCAN: 'after_sales_appointment_type.scan',
  RE_SCAN: 'RESCAN',
  RE_CHECK: 'after_sales_appointment_type.re_check',
  MID_REVIEW: 'mid.treatment.review',
  FINAL_REVIEW: 'chips_label.end-of-treatment_review',
  ADD_ATTACHMENT: 'after_sales_appointment_type.add_attachment',
  REMOVE_ATTACHMENT: 'after_sales_appointment_type.remove_attachment',
  ADD_BUTTON: 'after_sales_appointment_type.add_button',
  REMOVE_BUTTON: 'after_sales_appointment_type.remove_button'
};

export const TRANSLATION_REFERRALS_MAP: Record<string, string> = {
  INCOMPLETE: 'incomplete_referrals.incomplete_referrals',
  REVIEW_PENDING: 'pending_zenyum_review.pending_zenyum_review',
  REJECTED: 'referrals.rejected_referrals'
};

export const ERROR_CODE_MAP: Record<string, string> = {
  [ERROR_CODE.APPOINTMENT_BOOKED_NO_MODIFICATION_ALLOWED]:
    ERROR_CODE.APPOINTMENT_BOOKED_NO_MODIFICATION_ALLOWED,
  [ERROR_CODE.LEAVE_NOT_FOUND]: ERROR_CODE.LEAVE_NOT_FOUND
};

export const WORKING_HOURS_ERROR_MAP: Record<string, string> = {
  typeError: 'Timings not complete',
  startTimeAfterEndTime: 'Start time is after End time'
};

export const SEGMENT_TRANSLATION_MAPPER: Record<string, string> = {
  'treatmentSpecifications.treatmentSpecAttachments':
    TRACK_EVENTS.FILLED_ATTACHMENTS,
  'treatmentSpecifications.iprLocation': TRACK_EVENTS.FILLED_IPR_LOCATION,
  'treatmentSpecifications.crowdingResolvement':
    TRACK_EVENTS.FILLED_RESOLVE_CROWDING,
  'treatmentSpecifications.teethExtractionInstructions':
    TRACK_EVENTS.FILLED_TEETH_EXTRACTION_INSTRUCTIONS,
  'treatmentSpecifications.teethToExtract':
    TRACK_EVENTS.FILLED_TEETH_EXTRACTION_INSTRUCTIONS,
  'treatmentSpecifications.treatmentPlanTypes':
    TRACK_EVENTS.FILLED_TREATMENT_PLAN_TYPE,
  'treatmentFiles.photo': TRACK_EVENTS.FILLED_PHOTOS,
  'treatmentFiles.consentForm': TRACK_EVENTS.FILLED_CONSENT_FORM,
  'treatmentFiles.xray': TRACK_EVENTS.FILLED_XRAY,
  'treatmentFiles.scan': TRACK_EVENTS.FILLED_SCANS
};

export const TAB_TRANSLATION_MAPPER: Record<string, string> = {
  PRESCRIPTION_SUMMARY: TRACK_EVENTS.PRESCRIPTION_SUMMARY_TAB_CLICKED
};

export const TYPE_MAPPER: Record<string, string> = {
  ZENCLEAR: 'prescriptions.zenyumclear',
  ZENCLEAR_PLUS: 'prescriptions.zenyumclear_plus'
};

export const PLAN_TYPES: Record<string, string> = {
  ZENCLEAR: ' ZenyumClear™',
  ZENCLEAR_PLUS: 'ZenyumClear™ Plus'
};

export const AGE_GROUP_TRANSLATION_MAP: Record<string, string> = {
  '15-17': 'age.15_-_17_years_old',
  '18-20': 'age.18_-_20_years_old',
  '18-25': 'age.18_-_25_years_old',
  '21-30': 'age.21_-_30_years_old',
  '26-35': 'age.26_-_35_years_old',
  '31-40': 'age.31_-_40_years_old',
  '36-45': 'age.36_-_45_years_old',
  '41-50': 'age.41_-_50_years_old',
  '46-55': 'age.46_-_55_years_old',
  '>55': 'age.above_55_years_old',
  '>50': 'age.above_50_years_old',
  '<15': 'age.under_15_years_old',
  '<18': 'age.under_18_years_old',
  '15-25': 'age.15_-_25_years_old',
  '<16': 'age.under_16_years_old',
  '16-17': 'age.16_-_17_years_old',
  '36-55': 'age.36_-_55_years_old',
  '21-35': 'age.21_-_35_years_old',
  '36-50': 'age.36_-_50_years_old',
  '<20': 'age.under_20_years_old',
  '20-25': 'age.20_-_25_years_old',
  '<13': 'age.under_13_years_old',
  '13-17': 'age.13_-_17_years_old'
};
