import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

// eslint-disable-next-line import/no-unresolved
import { Message } from '@chat/chat-sdk/dist/types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Chat } from '@chat/chat-uikit/dist';
import cx from 'classnames';
import { cloneDeep } from 'lodash';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import chatClient from 'chatSDK';
import {
  ChatInput,
  ChatHeader,
  InternalWarning,
  Loader,
  GroupStatusUpdateMessageBlock,
  GroupSnoozeStatusMessageBlock,
  MessageBlock
} from 'components';
import If from 'components/Generics/If/If';
import { MOBILE_SCREEN_WIDTH } from 'constants/common';
import {
  INBOX_GROUP_NAME,
  INBOX_ROLE,
  MESSAGE_TYPE,
  SUPPORTED_FILE_FORMATS,
  TICKET_STATUS
} from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import {
  useGetTicketById,
  useUpdateTicketUrgency,
  useUpdateUnreadStatus
} from 'services/hooks/Ticket.hooks';
import {
  selectedTicket,
  selectedSerchedMessage,
  isWarningClosed,
  unreadNotification,
  ticketList,
  loginedUserDetails
} from 'store/atoms';
import { useIsExternalUser, useIsTPCouncil } from 'store/selectors/auth';
import { MapToObj, RichTextInput } from 'types/Inbox.types';
import { TicketUnreadStatus, TicketUrgency } from 'types/Tickets.types';
import { useViewport, translate } from 'utils';
import '@chat/chat-uikit/dist/index.css';
import './ChatMessage.css';
import {
  checkIsExternal,
  displayName,
  filterOutRoles,
  getParticipantCount,
  getDisplayNameForTPCouncil,
  getRoleName,
  segmentTrackEvent
} from 'utils/common';
import { getTicketList, useGetPatientUrl } from 'utils/customHooks/inbox';
import { useSuperUserAccess } from 'utils/customHooks/useAccess';
import { useQueryParams } from 'utils/customHooks/useQueryParams';
import { useSetError } from 'utils/customHooks/useSetError';
import { getMentionedUsersList } from 'utils/inbox';
import { showToast } from 'utils/toast/toast.util';

interface ChatMessageProps {
  handleChatClose?: () => void;
  isFromPatientDetails?: boolean;
  setShowSidePanel: (value: boolean) => void;
  showSidePanel?: boolean;
  setUnReadMessageCount?: React.Dispatch<React.SetStateAction<number>>;
}

const ChatMessage: FC<ChatMessageProps> = ({
  handleChatClose,
  isFromPatientDetails = false,
  setShowSidePanel,
  showSidePanel,
  setUnReadMessageCount
}) => {
  const { isSuperUser } = useSuperUserAccess();
  const handleSegmentTrack = useSegmentTrackChat();
  const { trackData, trackContextData, trackPatientData } = useTrackData();

  const isFirstRenderRef = useRef<boolean>(true);

  const isExternalUser = useRecoilValue(useIsExternalUser);
  const loginedUser = useRecoilValue(loginedUserDetails);
  const isTPCouncil = useRecoilValue(useIsTPCouncil);
  const warningClosed = useRecoilValue(isWarningClosed);
  const setNotification = useSetRecoilState<MapToObj>(unreadNotification);
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useRecoilState(selectedTicket);
  const [ticketsList, setTicketsList] = useRecoilState(ticketList);
  const unReadMessageCount = useRecoilValue(unreadNotification);

  const [selectedRole, setSelectedRole] = useState(INBOX_ROLE.INTERNAL);
  const [showWarning, setShowWarning] = useState(false);
  const searchedMessageDetails = useRecoilValue(selectedSerchedMessage);

  const patientUrl = useGetPatientUrl();

  const query = useQueryParams();
  const { width } = useViewport();
  const { setError } = useSetError();
  const isMobile = width < MOBILE_SCREEN_WIDTH;

  const [updateTicketUrgency] = useUpdateTicketUrgency({
    onCompleted: (data: TicketUrgency) => {
      const tickets = cloneDeep(ticketsList);
      const updatedTicketIndex = tickets.findIndex(
        (ticket) => ticket.id === data.updateTicketUrgency.id
      );

      if (updatedTicketIndex !== -1) {
        tickets[updatedTicketIndex].isUrgent =
          data.updateTicketUrgency.isUrgent;
      }
      setTicketsList(tickets);
      getSelectedTicketDetails();
    }
  });

  const [updateTicketUnreadStatus] = useUpdateUnreadStatus({
    onCompleted: (data: TicketUnreadStatus) => {
      setTicketsList((prevList) => {
        const tickets = cloneDeep(prevList);
        const updatedTicketIndex = tickets.findIndex(
          (ticket) => ticket.id === data.updateTicketReadStatus.id
        );
        if (updatedTicketIndex !== -1) {
          tickets[updatedTicketIndex].isRead =
            data.updateTicketReadStatus.isRead;
        }
        return tickets;
      });
      getSelectedTicketDetails();
    }
  });

  /**
   * @description
   * function tp filter mentionUsersList based on the participants list
   */
  const mentionUsersList = useMemo(() => {
    //@description if it is external user don't show any notifications
    if (isExternalUser) {
      return [];
    }
    return getMentionedUsersList(
      selectedTicketDetails.ticketUsers,
      loginedUser.id,
      isTPCouncil
    );
  }, [selectedTicketDetails.ticketUsers]);

  const getChatUsers = useCallback(
    (searchText?: string) => {
      if (searchText) {
        return mentionUsersList.filter((mentionDetails) =>
          mentionDetails.name
            .toLocaleLowerCase()
            .includes(searchText.toLocaleLowerCase())
        );
      }
      return mentionUsersList;
    },
    [mentionUsersList]
  );

  const [getSelectedDetailsByID] = useGetTicketById({
    onCompleted: (data) => {
      setSelectedTicketDetails(data.getTicketById);
      const tempTicketList = [...ticketsList];
      const updatedTicketIndex = tempTicketList.findIndex(
        (ticket) => ticket.id === Number(data?.getTicketById.id)
      );
      if (updatedTicketIndex >= 0 && data?.getTicketById) {
        const selectedDetails = cloneDeep(data.getTicketById);
        selectedDetails.ticketChatSearchMessage =
          tempTicketList[updatedTicketIndex]?.ticketChatSearchMessage;
        tempTicketList[updatedTicketIndex] = selectedDetails;
        setTicketsList(tempTicketList);
      }
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const getSelectedTicketDetails = useCallback(() => {
    if (selectedTicketDetails.id) {
      getSelectedDetailsByID({
        variables: {
          ticketId: selectedTicketDetails.id,
          loginedUserId: loginedUser.id
        }
      });
    }
  }, [selectedTicketDetails.id, getSelectedDetailsByID]);

  useEffect(() => {
    if (isExternalUser) {
      setSelectedRole(INBOX_ROLE.EXTERNAL);
    }
  }, [isExternalUser]);

  const inputWrapper = (
    children: ReactNode,
    toolboxAction?: string,
    message?: any
  ) => {
    if (selectedTicketDetails.status === TICKET_STATUS.CLOSE) {
      return <></>;
    }

    const isEdit = toolboxAction === 'EDIT';
    const isInternalEdit = message?.customAttributes?.is_internal;
    const isInternalStyle = isEdit
      ? isInternalEdit
      : selectedRole === INBOX_ROLE.INTERNAL;

    return (
      <div className='chat-message w-full'>
        <If condition={showWarning && !warningClosed && !isEdit}>
          <InternalWarning isInternal={selectedRole === INBOX_ROLE.INTERNAL} />
        </If>
        <div
          className={cx('', {
            internal: isInternalStyle,
            external: !isInternalStyle
          })}
        >
          <ChatInput
            selectedTab={selectedRole}
            onChange={(role) => {
              setSelectedRole(role);
            }}
            isEdit={isEdit}
            isInternalStyle={isInternalStyle}
          >
            {children}
          </ChatInput>
        </div>
      </div>
    );
  };

  const formattedTicketInfo = useMemo(
    () => getTicketList([selectedTicketDetails])[0],
    [selectedTicketDetails]
  );

  const handleUrgent = () => {
    const isUrgent = !selectedTicketDetails.isUrgent;
    const param = {
      variables: {
        ticketId: selectedTicketDetails.id,
        isUrgent
      }
    };
    handleSegmentTrack(CHAT_TRACK_EVENTS.MARK_URGENT, {
      ...trackData,
      ...trackContextData,
      zenchat_urgent: isUrgent ? 'Yes' : 'No',
      patient_id: trackPatientData.patient_id
    });
    const participantCount = getParticipantCount(
      formattedTicketInfo.ticketDetails.ticketUsers
    );
    updateTicketUrgency(param);
    const zenchatUpdateBqData = {
      zenchat_ID: trackData.zenchat_ID,
      chat_created_ts: trackData.chat_created_ts,
      no_of_participants: participantCount
    };
    handleSegmentTrack(
      CHAT_TRACK_EVENTS.ZENCHAT_UPDATE_BQ,
      zenchatUpdateBqData
    );
  };

  const handleUnread = (unreadStatus: boolean) => {
    const param = {
      variables: {
        ticketId: selectedTicketDetails.id,
        isRead: unreadStatus
      }
    };
    updateTicketUnreadStatus(param);
  };

  const trackUnread = (status: boolean) => {
    handleSegmentTrack(CHAT_TRACK_EVENTS.MARK_UNREAD, {
      ...trackData,
      ...trackContextData,
      zenchat_unread: status ? 'No' : 'Yes'
    });
  };

  useEffect(() => {
    // only show warning after first render
    setShowWarning(true);
  }, []);

  useEffect(() => {
    if (!selectedTicketDetails.isRead && selectedTicketDetails.id) {
      handleUnread(true);
    }
    if (isFirstRenderRef.current) {
      if (unReadMessageCount[`${selectedTicketDetails.id}`]) {
        setUnReadMessageCount?.((prev) => {
          if (prev)
            return prev - unReadMessageCount[`${selectedTicketDetails.id}`];
          return 0;
        });
      }
    }
    setNotification((prevState) => {
      const newState = { ...prevState };
      newState[`${selectedTicketDetails.id}`] = 0;
      return newState;
    });
    isFirstRenderRef.current = false;
  }, [selectedTicketDetails.id]);

  const getOwnerId = (): string => {
    const ownerIndex = selectedTicketDetails.ticketUsers.findIndex(
      (user) => user.groupName === INBOX_GROUP_NAME.CHAT_OWNER
    );
    return selectedTicketDetails.ticketUsers[ownerIndex]?.userId;
  };

  const getInitiatorId = (): string => {
    const initiatorIndex = selectedTicketDetails.ticketUsers.findIndex(
      (user) => user.groupName === INBOX_GROUP_NAME.CHAT_INITIATOR
    );
    return selectedTicketDetails.ticketUsers[initiatorIndex]?.userId;
  };

  const checkHasPermission = () => {
    const ownerId = getOwnerId();
    const initiatorId = getInitiatorId();
    return (
      ((ownerId === loginedUser.id || initiatorId === loginedUser.id) &&
        selectedTicketDetails.status !== TICKET_STATUS.CLOSE) ||
      isSuperUser
    );
  };
  const renderErrorMessage = (resendMessage: () => void) => {
    const NOT_SENT = translate('error.sentFailure');
    if (isMobile) {
      return (
        <>
          <button className='underline pr-1' onClick={resendMessage}>
            {NOT_SENT}
          </button>
        </>
      );
    }
    return NOT_SENT;
  };

  const handlePatientCardTracking = () => {
    handleSegmentTrack(CHAT_TRACK_EVENTS.PATIENT_CARD_OPEN, {
      ...trackData,
      ...trackContextData
    });
  };
  const handleCopyLinkTracking = () => {
    handleSegmentTrack(CHAT_TRACK_EVENTS.ZENCHAT_COPY_LINK, {
      zenchat_ID: trackData.zenchat_ID,
      zenchat_topic: trackData.zenchat_topic,
      zenchat_urgent: trackData.zenchat_urgent,
      zenchat_status: trackData.zenchat_status,
      zenchat_context_type: trackContextData.zenchat_context_type,
      treatment_plan_id: trackContextData.treatment_plan_id,
      treatment_plan_version_id: trackContextData.treatment_plan_version_id,
      treatment_plan_version: trackContextData.treatment_plan_version,
      treatment_plan_type: trackContextData.treatment_plan_type,
      patient_name: trackPatientData.patient_name,
      patient_id: trackPatientData.patient_id,
      opportunity_name: trackPatientData.opportunity_name,
      opportunity_id: trackPatientData.opportunity_id,
      user_permission: trackData.user_permission,
      user_id: loginedUser?.id,
      user_type: checkIsExternal(loginedUser?.groups) ? 'external' : 'internal',
      user_role: filterOutRoles(loginedUser?.groups)
    });
  };

  const onMessageSendSuccess = () => {
    //Updte ticket order on successfull sent message
    const tickets = [...ticketsList];
    const updatedTicketIndex = tickets.findIndex(
      (ticket) => ticket.id === selectedTicketDetails.id
    );
    if (updatedTicketIndex !== -1) {
      const updatedTicketDetails = { ...tickets[updatedTicketIndex] };
      updatedTicketDetails.updatedAt = new Date().toISOString();
      tickets.splice(updatedTicketIndex, 1);
      tickets.unshift(updatedTicketDetails);
      setTicketsList(tickets);
    }
  };

  const ticketOwnerId = useMemo(() => getOwnerId(), [selectedTicketDetails]);
  const isUserTicketOwner = ticketOwnerId == loginedUser.id;
  const showSnooze = isUserTicketOwner && !isExternalUser;

  const onMessageSendFail = ({ isText }: any) => {
    handleSegmentTrack(CHAT_TRACK_EVENTS.MESSAGE_FAILED, {
      contains_file: isText ? 'No' : 'Yes',
      ...trackData
    });
  };

  const onMessageSendRetry = ({ isText }: any) => {
    handleSegmentTrack(CHAT_TRACK_EVENTS.MESSAGE_RETRY, {
      contains_file: isText ? 'No' : 'Yes',
      ...trackData
    });
  };

  const onImagePreviewOpen = () => {
    handleSegmentTrack(CHAT_TRACK_EVENTS.MESSSAGE_IMAGE_VIEWED, trackData);
  };

  const handleSegment = (type: string, data: any) => {
    const { zenchat_ID, zenchat_topic, zenchat_status, zenchat_urgent } =
      trackData;

    const {
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type
    } = trackContextData;

    const segmentData: any = {
      zenchat_ID,
      message_id: data.id,
      zenchat_topic,
      zenchat_status,
      zenchat_urgent,
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type
    };

    switch (type) {
      case 'DELETE_MESSAGE_SUCCESS':
        segmentData.message_id = data.id;
        segmentTrackEvent(CHAT_TRACK_EVENTS.ZENCHAT_DELETE, segmentData);
        break;
      case 'EDIT_MESSAGE_SUCCESS':
        segmentData.message_id = data.id;
        segmentTrackEvent(CHAT_TRACK_EVENTS.ZENCHAT_EDIT, segmentData);
        break;
      default:
        break;
    }
  };

  const onMessageFileOpen = (fileName: string) => {
    const fileExtension = fileName.split('.').pop();
    window.analytics.track(CHAT_TRACK_EVENTS.OPEN_DOCUMENT, {
      file_type: fileExtension
    });
  };

  const trackUploadError = ({ totalFilesCount, trackingMsg, data }: any) => {
    let fileType;
    if (data?.unsupportedFiles) {
      const unsupportedFiles = Array.from(new Set(data.unsupportedFiles));
      fileType = unsupportedFiles.join(',');
    }
    handleSegmentTrack(CHAT_TRACK_EVENTS.UPLOAD_ERROR, {
      files_no: totalFilesCount,
      error_type: trackingMsg,
      file_type: fileType,
      ...trackData
    });
  };

  const customMessageBlock = (
    data: RichTextInput,
    type: string,
    message: any
  ) => {
    switch (type) {
      case MESSAGE_TYPE.GROUP_STATUS_UPDATE:
        return (
          <GroupStatusUpdateMessageBlock
            richText={data}
            isExternal={isExternalUser}
            isTPCouncil={isTPCouncil}
            message={message}
          />
        );
      case MESSAGE_TYPE.GROUP_SNOOZE_STATUS_UPDATE:
        return (
          <GroupSnoozeStatusMessageBlock richText={data} message={message} />
        );
      default:
        return (
          <MessageBlock
            isExternal={isExternalUser}
            isTPCouncil={isTPCouncil}
            richText={data}
            customData={message?.customData}
          />
        );
    }
  };

  const getMessageId = () => {
    const { ticketId, messageId } = searchedMessageDetails || {};
    const selectedTicketId = selectedTicketDetails.id;

    if (ticketId !== selectedTicketId) return;

    return messageId || query.get('messageId');
  };

  const customClassName = {
    messageBlock: (message: Message, action: string) => {
      const isInternal = !!message?.customAttributes?.is_internal;
      const isEdit = action === 'EDIT';
      if (isEdit) {
        return isInternal ? 'internal-edit-message' : 'external-edit-message';
      }
      return '';
    }
  };

  return (
    <div
      className={cx('flex flex-col h-full chat-message-container', {
        external: isExternalUser
      })}
    >
      <ChatHeader
        title={formattedTicketInfo.metaData.title}
        patientName={formattedTicketInfo.metaData.name}
        patientUrl={patientUrl}
        chatId={selectedTicketDetails.id}
        userList={selectedTicketDetails.ticketUsers}
        subtitle={formattedTicketInfo.metaData.subtitle}
        tpDetails={formattedTicketInfo.metaData.tpDetails}
        tpvDetails={formattedTicketInfo.metaData.tpvDetails}
        isUrgent={selectedTicketDetails.isUrgent}
        isUnread={selectedTicketDetails.isRead}
        isClosed={selectedTicketDetails.status === TICKET_STATUS.CLOSE}
        onClose={handleChatClose}
        showSnooze={showSnooze}
        snoozeUntill={selectedTicketDetails.snoozeInfo?.snoozeUntil}
        snoozeType={selectedTicketDetails.snoozeInfo?.snoozeType}
        handleUrgent={handleUrgent}
        handleUnread={(status) => {
          handleUnread(status);
          trackUnread(status);
        }}
        hasPermission={checkHasPermission()}
        showCloseButton={
          isFromPatientDetails &&
          (getOwnerId() === loginedUser.id || isSuperUser)
        }
        isExternalUser={isExternalUser}
        isFromPatientDetails={isFromPatientDetails}
        onPatientCardOpen={handlePatientCardTracking}
        copyLink={handleCopyLinkTracking}
        showTPVLink={!!selectedTicketDetails.keyLinks?.isTreatmentActive}
        patientId={formattedTicketInfo.metaData.patientId}
        getSelectedTicketDetails={getSelectedTicketDetails}
        setShowSidePanel={setShowSidePanel}
        showSidePanel={showSidePanel}
        isTPCouncil={isTPCouncil}
      />
      <div
        className={cx('flex-grow overflow-hidden', {
          'closed-ticket': selectedTicketDetails.status === TICKET_STATUS.CLOSE
        })}
      >
        <Chat
          chatClient={chatClient.getChatClient()}
          translate={translate}
          getChatUsers={getChatUsers}
          showAiAssistant={!isExternalUser && !isMobile}
          isChatClosed={selectedTicketDetails.status === TICKET_STATUS.CLOSE}
          setError={setError}
          customMessageBlock={customMessageBlock}
          modalContainer={'modal-container'}
          customInput={
            (!isExternalUser && !isMobile) ||
            selectedTicketDetails.status === TICKET_STATUS.CLOSE
              ? inputWrapper
              : undefined
          }
          getClassName={customClassName}
          getMessageBlockColors={(message: any) => {
            if (message?.customAttributes?.is_internal) {
              return {
                bgColor: '#FEF2D8',
                nameColor: '#775404'
              };
            }
            if (message.customAttributes.is_send_by_requester) {
              return {
                bgColor: '#1A6384',
                messageColor: '#FFF',
                dateColor: '#FFF'
              };
            }
            return {
              bgColor: '#FAFBFC',
              nameColor: '#207E68',
              messageColor: '#282829',
              dateColor: '#6E6E73'
            };
          }}
          inputExtras={{
            placeholder: translate('actions.send'),
            sendMessageExtras: {
              customAttributes: {
                is_internal: selectedRole === INBOX_ROLE.INTERNAL && !isMobile
              }
            }
          }}
          entityDetails={{ id: `${selectedTicketDetails.id}`, type: 'TICKET' }}
          extras={{
            getMessagesExtras: {
              customAttributeKeys: ['is_internal'],
              messageId: getMessageId()
            },
            onMessageSendSuccess,
            onMessageSendFail,
            onMessageSendRetry,
            onImagePreviewOpen,
            onMessageFileOpen,
            handleSegment,
            locale: loginedUser?.language || 'en'
          }}
          customName={(message: any) => {
            const { groupRole, customAttributes, senderDetails } =
              message || {};
            const { firstName, lastName } = senderDetails || {};

            if (customAttributes?.is_send_by_requester) {
              return '';
            }
            if (isTPCouncil) {
              return getDisplayNameForTPCouncil(
                groupRole?.teamName,
                firstName,
                lastName
              );
            }
            if (isExternalUser) {
              return getRoleName(groupRole?.teamName, firstName, lastName);
            }
            return displayName(firstName, lastName, groupRole?.teamName);
          }}
          customMessageBlockDate={(message: any, time: string) => {
            if (message?.customAttributes?.is_internal) {
              return `${time} - Internal Note`;
            }
            return time;
          }}
          unReadMessageCount={
            unReadMessageCount?.[`${selectedTicketDetails.id}`]
          }
          errors={{
            sendMessageError: { renderErrorMessage },
            getMessagesError: {
              onError: () => setError(translate('ERROR.DEFAULT_ERROR'))
            }
          }}
          fileUploadValidations={{
            maxFiles: {
              value: 10,
              hideErrorNotifications: true,
              onError: (data: any) => {
                const { totalFilesCount } = data;
                setError(translate('error.max_files_error'));
                trackUploadError({
                  totalFilesCount,
                  trackingMsg: 'More than 10 files'
                });
              }
            },
            fileFormats: {
              value: SUPPORTED_FILE_FORMATS,
              hideErrorNotifications: true,
              onError: (data: any) => {
                const { totalFilesCount } = data;
                const unsupportedFiles = Array.from(
                  new Set(data.unsupportedFiles)
                );
                trackUploadError({
                  totalFilesCount,
                  trackingMsg: 'Invalid file type',
                  data
                });
                setError(
                  translate('ZenChat.errorMessage.fileType').replace(
                    '<file_extension>',
                    unsupportedFiles.join(', ')
                  )
                );
              }
            },
            maxFileSize: {
              value: 100000000, // 100 MB in bytes
              hideErrorNotifications: true,
              onError: (data: any) => {
                const { totalFilesCount } = data;
                setError(translate('ERROR.MAX_FILE_SIZE_ERROR'));
                trackUploadError({
                  totalFilesCount,
                  trackingMsg: 'File size > 100MB'
                });
              }
            },
            minFileSize: {
              value: 0,
              hideErrorNotifications: true,
              onError: (data: any) => {
                const { totalFilesCount } = data;
                trackUploadError({
                  totalFilesCount,
                  trackingMsg: 'File size 0kb'
                });
                data?.files?.forEach((name: string) => {
                  showToast(
                    translate('error.0kbfile', {
                      X: name
                    }),
                    false
                  );
                });
              }
            }
          }}
          loader={<Loader />}
        />
      </div>
    </div>
  );
};

export default ChatMessage;
