import { FC, ReactNode } from 'react';

import { useHasAccess } from 'utils/hasAccess';

interface Props {
  yes?: ReactNode;
  no?: ReactNode;
  permission?: string | string[];
}

export const CanAccess: FC<Props> = ({ yes, no, permission = '' }) => {
  const hasAccess = useHasAccess(permission);
  return <>{hasAccess ? yes : no}</>;
};
