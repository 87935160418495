import { FC } from 'react';

import { ImageIcon, PdfIcon, VideoIcon } from 'assets/icons';

type ThumbnailNameProp = {
  name: string;
  type: 'img' | 'file';
  className?: string;
  textClassName?: string;
  isVideoFile?: boolean;
};

const ThumbnailName: FC<ThumbnailNameProp> = ({
  name = '',
  type = 'img',
  className = '',
  textClassName = '',
  isVideoFile
}) => {
  const renderFileIcon = () => {
    if (type === 'img') {
      return <ImageIcon title='image-icon' className='mt-0.75 ' />;
    } else if (isVideoFile) {
      return (
        <VideoIcon
          title='video-icon'
          className='w-4 h-4 md:mt-0.1rem mt-0.75 '
        />
      );
    } else {
      return (
        <PdfIcon title='pdf-icon' className='w-4 h-4 md:mt-0.1rem mt-0.75' />
      );
    }
  };

  return (
    <div className={`flex ${className}`}>
      <div className='flex-shrink-0'>{renderFileIcon()}</div>
      <div className='min-w-0 ml-1.5 flex items-center'>
        <p className={`${textClassName}`}>{name}</p>
      </div>
    </div>
  );
};

export default ThumbnailName;
