import { ReactNode } from 'react';

import {
  OperationVariables,
  LazyQueryHookOptions,
  QueryTuple,
  MutationHookOptions,
  MutationTuple,
  DefaultContext,
  ApolloCache,
  QueryHookOptions,
  QueryResult
} from '@apollo/client';

import { Option } from 'types/select';

import { PatientDetail, TreatmentPlanType } from './PatientList.types';
import { Ticket } from './Tickets.types';
import { Version } from './TreatmentPlanReview.types';

export interface ChatTopics {
  id: string;
  name: string;
  code: string;
  treatmentStage: string;
  chatInitiator: string;
  treatmentStageName: string;
  treatmentStageCode: string;
  isActive: boolean;
}

export interface TicketContext {
  id: string;
  contextType: string;
  contextDisplayOrder: number;
}
export interface GetAllContextType {
  getTopicContextType: TicketContext[];
}

export interface GetAllChatTopics {
  (config?: LazyQueryHookOptions<GetAllChatTopicsList>): QueryTuple<
    GetAllChatTopicsList,
    OperationVariables
  >;
}

export interface GetAllTPVParam {
  params: { patientId: string; treatmentId?: string };
  paginationInfo: {
    offset: number;
    limit: number;
  };
  rejectedBy?: string[];
}
export interface GetAllTPVs {
  (config?: LazyQueryHookOptions<GetAllTreatementPlan>): QueryTuple<
    GetAllTreatementPlan,
    OperationVariables
  >;
}
export interface GetContextType {
  (config?: LazyQueryHookOptions<GetAllContextType>): QueryTuple<
    GetAllContextType,
    OperationVariables
  >;
}

export interface TreatmentVersions {
  id: string;
  name: string;
}
export interface TreatmentPlan {
  id: string;
  name: string;
  versions: Version[] | null;
}
export interface TreatmentPlanEadges {
  edges: TreatmentPlanType[] | null;
}
export interface GetAllTreatementPlan {
  getTreatmentPlans: TreatmentPlanEadges;
}

export interface GetAllChatTopicsList {
  getAllChatTopics: ChatTopics[];
}

interface MedicalStaff {
  role: string;
  primary?: boolean;
  details: {
    firstName: string;
    lastName: string;
  };
}

export interface GetSidepanelTreatmentInfo {
  getTreatmentById: {
    limitation?: string;
    exclusion?: string;
    treatmentStage?: string;
    medicalStaff: {
      doctors: MedicalStaff[];
    };
    clinic: {
      name: string;
    };
    currentTreatmentKey?: string;
  };
}
export interface TicketInfo {
  status: string;
  participants: Participant[];
  tpvDetails: TPVDetails;
}

export interface TPVDetails {
  ipr: boolean;
  attachment: boolean;
  tpvMetaInfo: TPVMetaInfo[];
}

export interface TPVMetaInfo {
  label: string;
  value: number | string | ReactNode;
  show: boolean;
  icon?: JSX.Element;
}
export interface versions {
  id: string;
  salesforceInfo: {
    salesforceLink: string;
  };
}
export interface treatmentPlan {
  id: string;
  salesforceInfo: {
    salesforceLink: string;
  };
  versions: versions[] | null;
}

export interface Treatment {
  id: string;
  stage: string;
  status: string;
  type: string;
  treatmentPlan: treatmentPlan[] | null;
  patientId: string;
  details: {
    opportunityId: string;
  };
  clinic: {
    country: string;
    id: string;
    headClinicId?: string;
  };
  patient: {
    country: string;
  };
}

export interface ActiveTreatements {
  getActiveTreatmentsForPatient: Treatment[];
}
export interface GetActiveTreatement {
  (config?: LazyQueryHookOptions<ActiveTreatements>): QueryTuple<
    ActiveTreatements,
    OperationVariables
  >;
}

export interface TicketSequenceId {
  getNextTicketSequenceId: number;
}
export interface GetNextTicketSequenceId {
  (config?: LazyQueryHookOptions<TicketSequenceId>): QueryTuple<
    TicketSequenceId,
    OperationVariables
  >;
}

export interface Participant {
  userId: string;
  userTeamId?: string;
  userTeamName?: string;
  groupId: string;
  groupName: string;
  email?: string;
  isExternal: boolean;
  userFirstName: string;
  userLastName: string;
  userProfilePicture?: string;
  userTeamCode?: string;
  deactivatedAt?: string | null;
  userEmail?: string;
}

export interface TopicParticipantsList {
  getTopicParticipants: Participant[];
}
export interface GetTopicParticipantsList {
  (config?: LazyQueryHookOptions<TopicParticipantsList>): QueryTuple<
    TopicParticipantsList,
    OperationVariables
  >;
}

interface ContextInfo {
  contextType: string;
  contextValue: string;
  contextValueId: string;
}
export interface TicketUser {
  groupId: string;
  userId: string;
  isExternal: boolean;
}
export interface TreatmentInformationData {
  label: string;
  icon: JSX.Element;
  value: string | number;
  testId: string;
  show?: boolean;
  valueClassName?: string;
  hasPermission?: boolean;
}

export interface CustomChatAttributesInput {
  isInternal: boolean;
}
export interface DocumentInfoInput {
  name: string;
  ext: string;
  type: string;
  filePath: string;
  fileSize: number;
}
export interface SendMessageInput {
  id: string;
  sendBy?: string;
  text: string;
  documentInfo?: DocumentInfoInput;
  customAttributes?: CustomChatAttributesInput;
}

export interface ChatCustomData {
  headClinicId?: string;
}

export interface CreateTicket {
  id: number | undefined;
  topicId: string;
  ticketTopicContextInfo: ContextInfo[];
  ticketUsers: TicketUser[];
  isUrgent: boolean;
  clinicId: string | undefined;
  treatmentId: string | undefined;
  description?: string;
  sendMessageInput: SendMessageInput[];
  customData: ChatCustomData;
}

export interface NewTicketInfo {
  createTicket: Ticket;
}
export interface CreateNewTicket {
  (config?: MutationHookOptions): MutationTuple<
    NewTicketInfo,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface OwnerDetails {
  updateTicketOwnership: Participant[];
}

export interface UserDetails {
  removeUserFromTicket: {
    userId: string;
    userFirstName: string;
    userLastName: string;
  };
}
export interface AddUserDetails {
  addUserToTicket: Participant;
}
export interface AddUserToTicketBySelf {
  addUserToTicketByMyself: Participant;
}
export interface GetTicketUsers {
  getTicketParticipants: Participant[];
}
export interface RemoveUserFromTicket {
  (config?: MutationHookOptions): MutationTuple<
    UserDetails,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface UpdateTicketOwner {
  (config?: MutationHookOptions): MutationTuple<
    OwnerDetails,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface GroupInformation {
  id: string;
  name: string;
}

export interface GroupDetails {
  getGroups: GroupInformation[];
}

export interface GetGroups {
  (config?: QueryHookOptions): QueryResult<GroupDetails>;
}

export interface GetAllChatTopic {
  (config?: QueryHookOptions): QueryResult<GetAllChatTopicsList>;
}

export interface GetSidepanelTreatmentDetails {
  (config?: QueryHookOptions): QueryResult<GetSidepanelTreatmentInfo>;
}
export interface MapToObj {
  [index: string]: number;
}

export interface Staff {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  phone: string;
  groups: string[];
  profilePicture: string;
  details: {
    maxComplexity: number;
  };
}

export interface StaffDetails {
  staff?: Staff[];
  totalCount?: number;
}
export interface GetAllStaff {
  getAllStaff: StaffDetails;
}

export interface GetAllStaffList {
  (config?: LazyQueryHookOptions<GetAllStaff>): QueryTuple<
    GetAllStaff,
    OperationVariables
  >;
}

export interface GetAllStaffs {
  (config?: QueryHookOptions): QueryResult<GetAllStaff>;
}
export interface UseGetTicketUsers {
  (config?: LazyQueryHookOptions<GetTicketUsers>): QueryTuple<
    GetTicketUsers,
    OperationVariables
  >;
}

export interface AddUserToTicket {
  (config?: MutationHookOptions): MutationTuple<
    AddUserToTicketBySelf,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface NotificationCustomData {
  headClinicId: string;
}
export interface GroupDetail {
  entity_id: string;
  entity_type: string;
  id: string;
  name: string;
  custom_data?: NotificationCustomData;
}

export interface UserMentionInfo {
  name: string;
  title?: string;
  avatar: string | undefined;
  id: string;
  details: {
    id: string;
    name: string;
    team: string;
  };
}
export interface GroupStatusNotification {
  group_details: GroupDetail;
  user_list?: string[];
  message_category: string;
  property_name?: string;
  property_value?: string;
  group_message_id?: string;
  isMessageUnRead?: boolean;
  isMentionedInMessage?: string;
  rich_text_info?: RichTextInput;
}

export interface RichTextLocationInfo {
  key: number;
  offset: number;
  length: number;
}

export interface Block {
  rich_text_location_infos: RichTextLocationInfo[];
  text: string;
}

export interface RichTextDetail {
  id: string;
  name: string;
  team: string;
  is_snooze?: boolean;
  snooze_until?: Date;
  unsnooze_reason?: string;
}

export interface RichTextDetails {
  type: string;
  details: RichTextDetail;
}
export interface RichTextInput {
  blocks: Block[];
  rich_text_details: RichTextDetails[];
}

export interface MentionInput {
  text: string;
  rich_text_info?: RichTextInput;
}

export interface KeyLinks {
  salesforceLink?: string;
  adminPortalLink?: string;
  isTreatmentActive?: boolean;
}

export interface GetTreatmentById {
  getTreatmentById: Treatment;
}

export interface UseGetTreatmentById {
  (config?: LazyQueryHookOptions<GetTreatmentById>): QueryTuple<
    GetTreatmentById,
    OperationVariables
  >;
}

export interface UserListSegment {
  user_id: string;
  email: string;
  name: string;
  role: string;
}

export interface MemberListSegment {
  user_id: string[];
  email: string[];
  name: string[];
  role: string[];
}

export interface TaggedUserDetailsSegment {
  user_id: string[];
  name: string[];
  email: string[];
  user_permission: string[];
  role: string[];
}

export interface InboxFilterOptions {
  placeholder: string[];
  key: string;
  options: Option[];
  showOnlyInternal: boolean;
  isMobileViewAvailable: boolean;
  enableSearch: boolean;
}
export interface TicketByIdOptionalArg {
  viewTicketsWithoutMe: boolean;
}

export interface TicketPaginationInfo {
  offset: number;
  previousFetchDirection: string;
  previousScrollHeight: number;
  totalCount: number;
}

export interface BulkAssign {
  currentStep: number;
  showBulkAssignPopup: boolean;
  ownerFrom?: OwnerInfo;
  ownerTo?: OwnerInfo;
  ticketCount?: number;
  ticketIds?: Ticket[];
  isCountLoading?: boolean;
}

export enum BulkAssignActionKind {
  TOGGLE_BULK_ADD = 'TOGGLE_BULK_ADD',
  UPDATE_BULK_NEXT_STEP = 'UPDATE_BULK_NEXT_STEP',
  UPDATE_BULK_PREV_STEP = 'UPDATE_BULK_PREV_STEP',
  SET_OWNER_DETAILS = 'SET_OWNER_DETAILS'
}

export enum OWNER_TYPE {
  OWNER_FROM = 'OWNER_FROM',
  OWNER_TO = 'OWNER_TO'
}
export interface BulkAssignAction {
  type: BulkAssignActionKind;
  payload?: ReassignOwners;
}

export interface OwnerInfo {
  userId: string;
  userFirstName: string;
  userLastName: string;
  emailId: string;
  groups: string[];
}

export interface ReassignOwners {
  ownerFrom: OwnerInfo;
  ownerTo: OwnerInfo;
  ticketCount: number;
  ticketIds: Ticket[];
  isCountLoading: boolean;
}
export interface NewChatFormType {
  topic: any; //has both Option and Option[] type
  markasurgent: boolean;
  patient: PatientDetail;
  tpv: any;
  message: MentionInput;
  showTp: boolean;
}

export type InfiniteScrollProps = {
  dataLength: number;
  hasMore: boolean;
  next: () => void;
};

export interface submitLinkZenchatVariables {
  ticketId: number;
  linkedTicketId?: number;
  unlinkedTicketId?: number;
}

export interface Options {
  label: string;
  value?: {
    id: string;
    patientName: string | undefined;
    topicName: string;
  };
  url?: string;
}
