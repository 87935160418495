import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  QueryTuple
} from '@apollo/client';

import { DayWorkingInfo } from './Branches.types';
import { Staff } from './Inbox.types';
import { QueryCallbackFunctions } from './request';
import { Option } from './select';

export enum UserRoles {
  NURSE = 'NURSE',
  ADMIN = 'ADMIN',
  DOCTOR = 'EXTERNAL_DOCTOR',
  EXTERNAL_DOCTOR = 'EXTERNAL_DOCTOR',
  EMPLOYEE = 'EMPLOYEE',
  CUSTOMER_CARE = 'CUSTOMER_CARE',
  CASE_OPERATIONS = 'CASE_OPERATIONS',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  PARTNERSHIP_MANAGER = 'PARTNERSHIP_MANAGER',
  TREATMENT_PLANNING_DOCTOR = 'TREATMENT_PLANNING_DOCTOR',
  TP_COUNCIL = 'TP_COUNCIL'
}

export const UsersSettingsRoles: UserRoles[] = [
  UserRoles.EXTERNAL_DOCTOR,
  UserRoles.NURSE
];

export type GetUserInputType = {
  filter: {
    roles?: UserRoles[];
    headClinicId?: string;
    countries?: string[];
    searchKey?: string;
    branchId?: string;
    clinicIds?: string[];
    userType?: string;
  };
  paginationInfo?: {
    sortBy?: string;
    offset?: number;
    limit?: number;
    sortOrder?: string;
  };
  date?: string;
};
export type GetDoctorsInputType = {
  branchId: string;
};

export enum UserListSort {
  NAME = 'name'
}

export enum USER_SETTINGS_FILTERS {
  INT_EXT = 'intExt',
  COUNTRY = 'country',
  CLINIC = 'clinic',
  ROLE = 'role'
}

export type Clinic = {
  id: string;
  name: string;
  headClinicId: string;
};

export interface UserDetailsForm {
  username: string;
  email: string;
  password: string;
  phone: string;
  role: Option[];
  branches: Option[];
}

export interface Hours {
  start?: { label: string; value: string; message?: string };
  end?: { label: string; value: string; message?: string };
}
export interface AddUserForm extends Omit<UserDetailsForm, 'username'> {
  firstname: string;
  lastname: string;
  SUNDAY?: DayWorkingInfo;
  MONDAY?: DayWorkingInfo;
  TUESDAY?: DayWorkingInfo;
  WEDNESDAY?: DayWorkingInfo;
  THURSDAY?: DayWorkingInfo;
  FRIDAY?: DayWorkingInfo;
  SATURDAY?: DayWorkingInfo;
}

type Capacity = {
  capacity?: number | null;
};

export type User = {
  id: string;
  salutation: string;
  username: string;
  groups: [string];
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  phone: string;
  country: string;
  profilePicture: string;
  clinic: Clinic[];
  deactivatedAt: Date | null;
  maxComplexity: number;
  available: boolean;
  activeLeave?: {
    startDate: Date;
    endDate: Date;
  };
  capacity?: Capacity;
};

export type UserPermissionList = {
  leaveView: string;
  leaveManage: string;
  userEdit: string;
  loginEdit: string;
  dailyCapacityEdit: string;
};
export type StaffResponse = {
  staff?: User[];
};
export type GetUsersReponse = {
  getAllStaff: StaffResponse;
};

export type UserBasics = {
  id: string;
  firstName: string;
  lastName: string;
  groups: string[];
  profilePicture: string;
  available: boolean;
};

export type StaffBasics = {
  staff: UserBasics[];
  totalCount?: number;
  paginationInfo?: {
    limit?: number;
    offset?: number;
  };
};

export type GetBasicStaffInfoResponse = {
  getAllStaff: StaffBasics;
};

export type GetBasicStaffInfoInput = {
  filter: {
    roles?: UserRoles[];
    headClinicId?: string;
    countries?: string[];
    searchKey?: string;
    branchId?: string;
    clinicIds?: string[];
    userType?: string;
  };
  paginationInfo?: {
    sortBy?: string;
    offset?: number;
    limit?: number;
    sortOrder?: string;
  };
  date?: string;
};

export type StaffDetails = {
  id: string;
  salutation: string;
  username: string;
  groups: [string];
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  phone: string;
  country: string;
  profilePicture: string;
  clinic: Clinic[];
  deactivatedAt: Date | null;
  maxComplexity: number;
  available: boolean;
  activeLeave: {
    startDate: Date;
    endDate: Date;
    timezone: string;
  };
  capacity?: Capacity;
};

export type GetStaffDetailsInfoResponse = {
  getStaff: StaffDetails;
};

export type GetStaffDetailsInfoInput = {
  id: string;
  date: string;
};

export type GetDoctorsReponse = {
  getAllDoctors: Staff[];
};
export type GetBranch = {
  id: string;
  name: string;
};
export type GetBranchesReponse = {
  getBranchesOfHeadClinic: GetBranch[];
};
export interface UpdateUserStatusResponse {
  userId: string;
  deactivatedAt: Date;
}

export interface UpdateUserStatusInput {
  id: string;
  isActive: boolean;
}

export interface UserDetails {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export interface AddUserInput {
  userDetails: UserDetails;
  roles: string[];
  branches: string[];
}

export interface AddUserResponse {
  addUser: { id: string; username: string };
}

export interface UpdateUserResponse {
  updateUser: {
    id: string;
    email: string;
    phone: string;
    lastName: string;
    firstName: string;
    username: string;
    groups: string[];
    clinic: Clinic[];
    capacity: Capacity;
  };
}

export interface UpdateUserGroupsResponse {
  updateUserGroups: {
    id: string;
    name: string;
  };
}
export interface UpdateUserClinicsResponse {
  updateUserClinics: {
    id: string;
    name: string;
  };
}

export interface UpdateUserInput {
  email: string;
  phone: string;
  mobile: string;
  lastName: string;
  firstName: string;
  salutation: string;
  profilePicture: string;
  language: string;
  username: string;
  clinic: Clinic[];
}

export interface updateUserDetails {
  id: string;
  input: UpdateUserInput;
}

export interface Countries {
  label: string;
  value: string;
}

export interface Roles {
  label: string;
  value: string;
}

export interface Languages {
  value: string;
  label: string;
}

export interface GetCountriesReponse {
  getSupportedCountries: Countries[];
}

export interface GetRolesReponse {
  getSupportedRoles: Roles[];
}

export interface GetLanguagesReponse {
  getSupportedLanguages: Languages[];
}
export interface GetUsersList {
  (config?: QueryHookOptions<GetUsersReponse, GetUserInputType>): QueryResult<
    GetUsersReponse,
    GetUserInputType
  >;
}

export interface GetBasicStaffInfo {
  (
    config?: QueryHookOptions<GetBasicStaffInfoResponse, GetBasicStaffInfoInput>
  ): QueryResult<GetBasicStaffInfoResponse, GetBasicStaffInfoInput>;
}

export interface GetStaffDetailsInfo {
  (
    config?: QueryHookOptions<
      GetStaffDetailsInfoResponse,
      GetStaffDetailsInfoInput
    >
  ): QueryResult<GetStaffDetailsInfoResponse, GetStaffDetailsInfoInput>;
}

export interface GetUsersListLazy {
  (
    config?: LazyQueryHookOptions<GetUsersReponse, GetUserInputType>
  ): QueryTuple<GetUsersReponse, GetUserInputType>;
}

export interface GetBranches {
  (config?: QueryHookOptions): QueryResult<GetBranchesReponse>;
}
export interface UpdateUserStatus {
  (
    config?: MutationHookOptions<
      UpdateUserStatusResponse,
      UpdateUserStatusInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    UpdateUserStatusResponse,
    UpdateUserStatusInput,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface AddUser {
  (
    config?: MutationHookOptions<
      AddUserResponse,
      AddUserInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    AddUserResponse,
    AddUserInput,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface UpdateUser {
  (config?: MutationHookOptions): MutationTuple<UpdateUserResponse, any>;
}

export interface UpdateUserGroups {
  (config?: MutationHookOptions): MutationTuple<UpdateUserGroupsResponse, any>;
}

export interface UpdateUserClinics {
  (config?: MutationHookOptions): MutationTuple<UpdateUserClinicsResponse, any>;
}

export interface GetCountries {
  (config?: QueryHookOptions): QueryResult<GetCountriesReponse>;
}

export interface GetRoles {
  (config?: QueryHookOptions): QueryResult<GetRolesReponse>;
}

export interface GetLanguages {
  (config?: QueryHookOptions): QueryResult<GetLanguagesReponse>;
}

export interface UserInfoFormType {
  username: string;
  email: string;
  phone: string;
  branches?: Option[];
  role?: Option[];
  dailyCapacity?: string;
}
