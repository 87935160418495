import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { Tab } from 'components';
import { If } from 'components/Generics';
import { loginedUserDetails } from 'store/atoms';
import { tabHidePermissions } from 'utils/patientpageUtils';

export interface TabType {
  label: string;
  count?: string;
  id: string;
  testId?: string;
  endIcon?: JSX.Element;
}

interface TabsProps {
  tabs: Array<TabType>;
  selectedTab: string;
  onChange: (id: string) => void;
  className?: string;
  tabClassName?: string;
  countColour?: string;
  padding?: string;
}

export const TabContainer: FC<TabsProps> = ({
  tabs,
  selectedTab = tabs[0]?.id,
  onChange,
  className,
  tabClassName,
  countColour,
  padding
}) => {
  const { permissions = [] } = useRecoilValue(loginedUserDetails);

  return (
    <div className={`min-w-max flex space-x-8 ${className}`}>
      {tabs?.map((tab) => (
        <If
          condition={!permissions?.includes(tabHidePermissions[tab.id])}
          key={tab.id}
        >
          <Tab
            endIcon={tab?.endIcon}
            selected={selectedTab === tab?.id}
            label={tab?.label}
            count={tab?.count}
            handleClick={(e) => {
              e.stopPropagation();
              onChange(tab?.id);
            }}
            tabClassName={tabClassName}
            countColour={countColour}
            padding={padding}
          />
        </If>
      ))}
    </div>
  );
};
