import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { toDraftJs } from '@chat/chat-uikit/dist';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';

import { RichTextInput } from 'types/Inbox.types';
import { translate } from 'utils';
import { stringPipe } from 'utils/common';
import { getMentionText } from 'utils/inbox';

import './style.css';

interface Props {
  isExternal: boolean;
  isTPCouncil: boolean;
  richText: RichTextInput;
  customData?: any;
}

const MessageBlock: FC<Props> = ({
  richText,
  isExternal,
  isTPCouncil,
  customData
}) => {
  const draftJsFormat = toDraftJs(richText);
  const formatEntity = ({ data }: any) => {
    const entityDetails = {
      team: data.mention.details.team,
      name: data.mention.details.name,
      text: data.mention.name
    };
    const mentionText = getMentionText(isExternal, isTPCouncil, entityDetails);
    return `<span className="text-PRIMARY">@${mentionText}</span>`;
  };
  const htmlText = draftToHtml(
    draftJsFormat,
    undefined,
    undefined,
    formatEntity
  );

  const getSummaryHTML = () => {
    return `<p className="summary-message">${translate('ticketSummary')}</p>`;
  };

  const isSummary = customData?.is_summary ?? false;
  // heading for AI generated message
  const addSummaryHTML = (text: string) =>
    isSummary ? getSummaryHTML().concat(text) : text;

  const replaceEmptyParagraphs = (text: string) =>
    text.replaceAll('<p></p>', '<br />');

  const formatMessageHTMLText = (html: string) => {
    return stringPipe(addSummaryHTML, replaceEmptyParagraphs)(html);
  };

  const formattedHTMLText = formatMessageHTMLText(htmlText);
  return <>{parse(formattedHTMLText)}</>;
};

export default MessageBlock;
