import { sortBy } from 'lodash';

import { EXTERNAL_ROLES } from 'constants/common';
import {
  INBOX_CONTEXT_TYPE,
  INBOX_GROUP_NAME,
  TEAM_NAME
} from 'constants/inbox';
import {
  Participant,
  RichTextInput,
  UserMentionInfo,
  NotificationCustomData,
  UserListSegment,
  MemberListSegment,
  TaggedUserDetailsSegment
} from 'types/Inbox.types';
import { LoginedUser } from 'types/Login.types';
import { Ticket } from 'types/Tickets.types';

import {
  getFullName,
  getRoleName,
  getDisplayNameForTPCouncil,
  getTeamName,
  isDataExist
} from './common';
import { translate } from './locale';

const formatParticipantName = (
  participent: Participant | undefined,
  userDetails: LoginedUser
) => (
  <>
    <span>
      {getFullName({
        firstName: participent?.userFirstName,
        lastName: participent?.userLastName,
        salutation: ''
      })}
    </span>
    {userDetails.id === participent?.userId ? (
      <span className='text-DEFAULT_TEXT font-bold'>
        &nbsp;({translate('INBOX.YOU')})
      </span>
    ) : (
      participent?.userTeamName && (
        <span>&nbsp;({getTeamName(participent?.userTeamCode || '-')})</span>
      )
    )}
  </>
);

const checkIsOwnerAndInitiatorSame = (
  owner: Participant,
  initiator: Participant,
  setIsOwnerAndInitiatorSame?: (flag: boolean) => void
): boolean => {
  if (owner.userId === initiator.userId) {
    setIsOwnerAndInitiatorSame?.(true);
    return true;
  }
  setIsOwnerAndInitiatorSame?.(false);
  return false;
};

const getFilteredParticipantsList = (
  participantsList: Participant[],
  setIsOwnerAndInitiatorSame?: (flag: boolean) => void
): Participant[] => {
  const initiator: Participant[] = [];
  const owner: Participant[] = [];
  let formattedList = participantsList.filter((participant) => {
    if (participant.groupName === INBOX_GROUP_NAME.CHAT_INITIATOR) {
      initiator.push(participant);
    } else if (participant.groupName === INBOX_GROUP_NAME.CHAT_OWNER) {
      owner.push(participant);
    } else if (participant.groupName !== INBOX_GROUP_NAME.CHAT_SUPER_USER) {
      return participant;
    }
  });
  if (isDataExist(formattedList)) {
    formattedList = sortBy(formattedList, ['userFirstName', 'userLastName']);
  }
  if (
    isDataExist(owner) &&
    isDataExist(initiator) &&
    checkIsOwnerAndInitiatorSame(
      owner[0],
      initiator[0],
      setIsOwnerAndInitiatorSame
    )
  ) {
    return [...owner, ...formattedList];
  }
  return [...owner, ...initiator, ...formattedList];
};

const formatMentionName = (
  firstName: string,
  lastName: string,
  team = '',
  isTPCouncil = false
) => {
  const fullName = getFullName({
    firstName: firstName,
    lastName: lastName,
    salutation: ''
  });
  const teamName = getTeamName(team || '-');

  if (isTPCouncil && team === TEAM_NAME.EXTERNAL_DOCTOR.key) {
    return `${teamName}`;
  }
  return `${fullName} (${teamName})`;
};

const getStatusLabel = (status: string): string => {
  switch (status) {
    case 'URGENT':
      return translate('labels.urgent');
    case 'CLOSE':
      return translate('messageStatus.closed');
    case 'OPEN':
      return translate('labels.ongoing');
    default:
      return status;
  }
};

const getMentionDetails = (
  participant: Participant,
  isTPCouncil?: boolean
): UserMentionInfo => {
  const name = formatMentionName(
    participant?.userFirstName,
    participant?.userLastName,
    participant.userTeamCode,
    isTPCouncil
  );
  return {
    name,
    ...(!(
      isTPCouncil && participant.userTeamCode === TEAM_NAME.EXTERNAL_DOCTOR.key
    )
      ? { title: participant?.email || participant?.userEmail || '' }
      : {}),
    avatar: participant?.userProfilePicture,
    id: participant?.userId,
    details: {
      id: participant?.userId,
      name,
      team: participant?.userTeamCode || ''
    }
  };
};
const getMentionedUsersList = (
  participantsList: Participant[],
  loginedUserId?: string,
  isTPCouncil?: boolean
) => {
  const mentionUserList: Record<string, UserMentionInfo> = {};
  participantsList.forEach((participant) => {
    if (
      loginedUserId !== participant.userId &&
      !participant.deactivatedAt &&
      participant.groupName !== INBOX_GROUP_NAME.CHAT_SUPER_USER
    ) {
      mentionUserList[participant.userId] = getMentionDetails(
        participant,
        isTPCouncil
      );
    }
  });

  const sortedMentionedUsers = sortBy(Object.values(mentionUserList), ['name']);
  return sortedMentionedUsers || [];
};

const getRole = (
  isRoleExternal: boolean,
  richText: RichTextInput,
  key: number
) => {
  return isRoleExternal
    ? getRoleName(
        richText.rich_text_details[key].details.team,
        richText.rich_text_details[key].details.name
      )
    : getRoleName(richText.rich_text_details[key].details.team);
};

const formatMentionedMessage = (
  richText: RichTextInput,
  isExternal: boolean
) => {
  let offset = 0;
  const str: string[] = [];

  const isExternalRole = (team: string) => EXTERNAL_ROLES.includes(team);

  richText?.blocks?.forEach((block) => {
    if (!isDataExist(block.rich_text_location_infos) || !isExternal) {
      str.push(block.text);
    } else {
      block.rich_text_location_infos.forEach((item, i) => {
        const isMention =
          richText.rich_text_details[item.key].type === 'MENTION';

        // skipping formatting of rich text
        if (!isMention) {
          return;
        }

        const isRoleExternal = isExternalRole(
          richText.rich_text_details[item.key].details.team
        );
        if (offset < item.offset) {
          str.push(block.text.slice(offset, item.offset));
          offset = item.offset;
        }
        const role = getRole(isRoleExternal, richText, item.key);
        str.push(`@${role}`);
        offset = offset + item.length;
        if (
          i === block.rich_text_location_infos.length - 1 &&
          offset < block.text.length - 1
        ) {
          str.push(block.text.slice(offset, block.text.length));
        }
      });
    }
  });

  return str.join('');
};

const getPlaceHolder = (array: string[]) =>
  array.map((Placeholder) => translate(Placeholder)).join('/');

const isForDifferentHeadClinic = (
  customData?: NotificationCustomData,
  headClinicId?: string
): boolean => {
  if (customData && headClinicId) {
    const { headClinicId: incomingId } = customData;
    return incomingId !== headClinicId;
  }
  return false;
};

const getSegmentUserDetails = (ticketUsers: Participant[]) => {
  let owner: UserListSegment = {
    user_id: '',
    email: '',
    name: '',
    role: ''
  };
  let initiator: UserListSegment = {
    user_id: '',
    email: '',
    name: '',
    role: ''
  };

  const member: MemberListSegment = {
    user_id: [],
    email: [],
    name: [],
    role: []
  };

  ticketUsers.forEach((ticketUser) => {
    const name = getFullName({
      firstName: ticketUser.userFirstName,
      lastName: ticketUser.userLastName,
      salutation: ''
    });
    if (ticketUser.groupName === INBOX_GROUP_NAME.CHAT_OWNER) {
      owner = {
        user_id: ticketUser.userId,
        email: ticketUser.email || ticketUser.userEmail || '',
        name,
        role: ticketUser.userTeamCode || ''
      };
    } else if (ticketUser.groupName === INBOX_GROUP_NAME.CHAT_INITIATOR) {
      initiator = {
        user_id: ticketUser.userId,
        email: ticketUser.email || ticketUser.userEmail || '',
        name,
        role: ticketUser.userTeamCode || ''
      };
    } else if (ticketUser.groupName !== INBOX_GROUP_NAME.CHAT_SUPER_USER) {
      member.user_id.push(ticketUser.userId);
      (ticketUser.email || ticketUser.userEmail) &&
        member.email.push(ticketUser.email || ticketUser.userEmail || '');
      member.name.push(name);
      member.role.push(ticketUser.userTeamCode || '');
    }
  });
  return {
    owner,
    initiator,
    member
  };
};

const getTaggedUserDetails = (
  mentionedUsers: any[],
  ticketUsers: Participant[]
) => {
  const mentionedUserSegmentDetails: TaggedUserDetailsSegment = {
    user_id: [],
    name: [],
    email: [],
    user_permission: [],
    role: []
  };

  const ticketUsersMap = new Map(ticketUsers.map((key) => [key.userId, key]));

  mentionedUsers.forEach((mentionedUser) => {
    if (ticketUsersMap.get(mentionedUser.id)) {
      const name = getFullName({
        firstName: ticketUsersMap.get(mentionedUser.id)?.userFirstName,
        lastName: ticketUsersMap.get(mentionedUser.id)?.userLastName,
        salutation: ''
      });
      mentionedUserSegmentDetails.user_id.push(
        ticketUsersMap.get(mentionedUser.id)?.userId || ''
      );
      mentionedUserSegmentDetails.name.push(name);
      mentionedUserSegmentDetails.email.push(
        ticketUsersMap.get(mentionedUser.id)?.email || ''
      );
      mentionedUserSegmentDetails.user_permission.push(
        ticketUsersMap.get(mentionedUser.id)?.groupName || ''
      );
      mentionedUserSegmentDetails.role.push(
        ticketUsersMap.get(mentionedUser.id)?.userTeamName || ''
      );
    }
  });
  return mentionedUserSegmentDetails;
};

const getIfOwnerAndInitiatorIsSame = (participants: Participant[]): boolean => {
  let initiatorId = '';
  let ownerId = '';
  participants.forEach((participant) => {
    if (participant.groupName === INBOX_GROUP_NAME.CHAT_INITIATOR) {
      initiatorId = participant.userId;
    } else if (participant.groupName === INBOX_GROUP_NAME.CHAT_OWNER) {
      ownerId = participant.userId;
    }
  });
  if (ownerId === initiatorId) {
    return true;
  }
  return false;
};

const checkPatientCardAndTicketPatientsSame = (
  patientId: string | undefined,
  ticketDetails: Ticket
): boolean => {
  const ticketPatientId = ticketDetails.ticketTopicContextInfo.filter(
    (context) => context.contextType === INBOX_CONTEXT_TYPE.PATIENT
  )[0]?.contextValueId;
  return ticketPatientId === patientId;
};

const checkExternalRole = (team: string) => EXTERNAL_ROLES.includes(team);
const getMentionText = (
  isExternalUser: boolean,
  isTPCouncil: boolean,
  entityDetails: {
    team: string;
    name: string;
    text: string;
  }
) => {
  const { team, text, name } = entityDetails;
  const isExternalRole = checkExternalRole(team);
  if (isExternalUser && isExternalRole) {
    return getRoleName(team, name);
  } else if (isExternalUser && !isExternalRole) {
    return getRoleName(team);
  } else if (isTPCouncil && team === TEAM_NAME.EXTERNAL_DOCTOR.key) {
    return getDisplayNameForTPCouncil(team);
  } else {
    return text;
  }
};

export {
  getStatusLabel,
  getTaggedUserDetails,
  getSegmentUserDetails,
  formatParticipantName,
  getMentionedUsersList,
  formatMentionedMessage,
  isForDifferentHeadClinic,
  getFilteredParticipantsList,
  getIfOwnerAndInitiatorIsSame,
  checkPatientCardAndTicketPatientsSame,
  getPlaceHolder,
  getMentionText
};
