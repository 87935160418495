import {
  ApolloCache,
  DefaultContext,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult
} from '@apollo/client';

import { AllStages } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import { CONSENT_FORM_STATUS } from 'constants/ConsentForm.constants';
import { TREATMENT_PLAN_VERSION_STATUS } from 'constants/options';
import { Option } from 'types/select';

import { AutoSuggestions } from './autoSuggest.types';
import { CalendarStatus } from './Calendar.types';
import { Classification, FileOrigin, FilesData } from './Files.types';
import { TreatmentStaff } from './GiveInstructions.types';
import { PatientGuardian, TPVCategory } from './PatientCard.types';
import { QueryCallbackFunctions } from './request';
import { TPVFormField, TPVFormTypes } from './TPVForm.types';
import {
  PriorTreatment,
  TreatmentSpecifications
} from './TreatmentPlanning.types';

export interface AuthMutation {
  (config?: MutationHookOptions): MutationTuple<
    any,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface ResubmissionFilesInfo {
  (config?: MutationHookOptions): MutationTuple<
    any,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}

export type MutationType<T> = {
  (config?: MutationHookOptions): MutationTuple<
    T,
    OperationVariables,
    DefaultContext,
    ApolloCache<T>
  >;
};

export type UpdateStatusById = {
  id: string;
  status: string;
};

interface Patient {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
  profilePicture: string;
  details: { ageCategory: string };
}

export interface PatientBasicDetails {
  ageCount?: string;
  guardian?: PatientGuardian;
  id?: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  image?: string;
  phone?: string;
  age?: string;
  doctorName?: string;
  clinic?: string;
  employeeName?: string;
  ImageDocuments?: FilesData[];
  patientId: string;
  profilePic?: string;
  family?: Family[];
  consentStatus?: CONSENT_FORM_STATUS;
  files: FilesData[];
  tabs?: { id: string; label: string }[];
  type?: string;
  opportunityId?: string;
  priorTreatment?: PriorTreatment;
  consentFile?: string;
  stage?: AllStages;
  doctors?: TreatmentStaff[];
  PartnershipsManager?: string;
  clinicCountry?: string;
  terminated?: boolean;
}
export interface ReferralPatientDetails {
  consentStatus?: string;
  consentFile?: string;
}

export interface Doctors {
  primary: boolean;
  role: string;
  details: Employee;
}

export interface PatientDetail {
  firstName: string;
  id: string;
  primaryCountry: string;
  lastName: string;
  phone: string;
  email: string;
  name?: string;
  profilePicture: string;
  salutation: string;
  details: {
    ageCategory?: string;
    age?: string;
  };
  guardian?: PatientGuardian;
}
export interface Employee {
  id: string;
  firstName: string;
  salesforceId: string;
  lastName: string;
  email?: string;
  primaryCountry?: string;
  phone?: string;
  details: {
    maxComplexity: number;
  };
}
interface MedicalStaff {
  doctors: Doctors[];
}

export interface PaginationInfo {
  length: number;
  offset: number;
  total: number;
}

interface Clinic {
  id: string;
  name: string;
  headClinicId?: string;
}
interface Details {
  opportunityId: string;
}

type TreatmentAppointment = {
  id: string;
  status: string;
  bookingStartTime: Date;
  bookingEndTime: Date;
  statusInfo: {
    statusCode: CalendarStatus;
  };
};
export interface versions {
  id: string;
  acceptanceStatus: string;
  treatmentPlanId: string;
}
export interface TPV {
  versions: versions[];
  statusUpdatedAt?: Date;
  alignerProductionInfo: {
    productionRecord: {
      expectedDate: {
        from: string;
        to: string;
      };
    };
  };
  pickUpDetails: {
    actualDate: string;
  };
}
export interface Treatment {
  id: string;
  patient: Patient;
  clinic: Clinic;
  status: string;
  employee: Employee;
  lastVisitedDate: string;
  stage: string;
  type: string;
  details: Details;
  treatmentAppointments: TreatmentAppointment[];
  pendingFrom: Date;
  pendingFor: number;
  treatmentPlan: TPV[];
  medicalStaff: MedicalStaff;
  actions?: TreatmentAction[];
  isActiveTPVCategoryA?: boolean;
  treatmentSpecifications: { treatmentPlanTypes: string[] };
}
export interface TreatmentType {
  id: string;
  patient: Patient;
  stage: string;
  pendingFor: number;
  treatmentPlan: TPV[];
}
export type FormattedTreatment = {
  isActiveTPVCategoryA?: boolean;
  image?: string;
  name?: string;
  phone?: number | string;
  status: string;
  action?: string;
  actionRequired?: TreatmentAction;
  patientId: string;
  id: string;
  stage: string;
  branch: string;
  appointment: TreatmentAppointment;
  pendingFor: number;
  profilePicture?: string;
};

export interface GetTreatments {
  edges: Array<Treatment>;
  pagination: PaginationInfo;
}
export interface GetTreatmentsArray {
  edges: Array<TreatmentType>;
  pagination: PaginationInfo;
}
export interface TreatementData {
  getTreatments: GetTreatments;
}
export interface TreatmentAction {
  entityId: string;
  entityName: string;
  pendingFrom: Date;
  name: string;
}

export interface GetTreatementsByView {
  getTreatmentsByView: {
    edges: Treatment[];
    pageInfo: {
      offset: number;
      hasNext: boolean;
    };
  };
}
export interface TreatementInfo {
  getTreatments: GetTreatmentsArray;
}

export interface GetTreatment {
  (config?: QueryHookOptions): QueryResult<TreatementInfo>;
}

export enum SubmittedBy {
  Doctor = 'DOCTOR',
  Patient = 'PATIENT'
}

export interface Documents {
  documentId: string;
  documentType: string;
  sourceType: string;
  presignedUrl: string;
  thumbnail?: string;
  documentName: string;
  extension: string;
  origin: FileOrigin;
  classification: Classification;
  createdAt: string;
  submittedBy?: SubmittedBy;
  resubmissionStatus?: string;
}

interface Clinic {
  id: string;
  name: string;
  scannerType: string;
  sfClinicId?: string;
  country: string;
  staffs: Staff[];
  isRetainerEnabled?: boolean;
}

export type Family = {
  id: string;
  type: string;
  opportunityId?: string;
  cpTreatmentId?: string;
  hasCaseOpsIssue: boolean;
  status: string;
  stage: string;
  scanPaymentRequired?: boolean | null;
  treatmentPlan?: TreatmentPlanType[];
  purchaseInfo?: {
    paymentStatus: string;
  };
};

export type TreatmentGoalsObject = {
  name: string;
  procedures: string[];
};

type ToothChart = { position: string; value: any; name: string }[];

export type ToothOptionInput = {
  name: string;
  value?: ToothChart;
};

export type TPVPrescription = {
  type: TPVFormTypes;
  treatmentGoals: {
    name: string;
    procedures: [{ name: string; value?: [{ name: string; value: string }] }];
  }[];
  archesToTreat: string[];
  toothMovementRestrictions?: string;
  overbite?: string;
  midlines?: string;
  midlinesShifts?: string[];
  teethToExtract?: string;
  prosthesis?: string;
  treatmentPlanningLab: Option;
  prosthesisIprToAvoid?: string;
  prosthesisAttachmentToAvoid?: string;
  specialNotes: string;
  translatedSpecialNotes: string;
  submissionReason: string;
  refinementGoals: ToothOptionInput[];
  attachments: string;
  iprInfo: ToothOptionInput;
  alignerInfo?: TPVFormField[];
  designId?: string;
  iprInfoText: string;
};

export enum TREATMENT_PLAN_TYPE {
  ZENCLEAR = 'ZENCLEAR',
  ZENCLEAR_PLUS = 'ZENCLEAR_PLUS'
}
export interface VersionDetails {
  treatmentPlanId: string;
  type: TREATMENT_PLAN_TYPE;
  details: {
    prescription: TPVPrescription;
    resimParentId: string;
    resimChildIds: string[];
    designSpecification: {
      isPlanNeeded: boolean;
      category: { type: TPVCategory };
    };
    metadata: { previousStatus: TREATMENT_PLAN_VERSION_STATUS };
  };
  id: string;
  name: string;
  url3DViewer: string;
  documents: Array<Document>;
  manufacturer: string;
  acceptanceStatus: TREATMENT_PLAN_VERSION_STATUS;
  numberOfZenStages: number;
  numberOfSets: number;
  teethToExtract: string;
  riskAssessmentForAppt: string;
  patientComments: string;
  zenyumCouncilComments: string;
  treatingDoctorComments: string;
  translatedTreatingDoctorComments: string;
  translatedZenyumCouncilComments: string;
  url3DSimulationVideo: string;
  urlSmileSummary: string;
  teethExtractionZenplus: string;
  confirmedTeethExtractions: string;
  accessToken3dViewer: string;
  ipr: boolean;
  attachments: boolean;
  createdAt: Date;
  rejectionInfo: {
    translatedReason: string;
    rejectedBy: string;
    reason: string;
    isPublic: boolean;
  };
  rejectionReason: string;
  doctor3dViewerUrl: string;
  acceptanceStatusUpdatedAt: Date;
  treatmentPlanningLab: string;
  label?: string;
  value?: string;
}

export interface VersionGroup {
  ZENCLEAR: VersionDetails[];
  ZENCLEAR_PLUS: VersionDetails[];
}

export interface TreatmentPlanType {
  id: string;
  name: string;
  status: string;
  type: string;
  treatmentSpecifications: TreatmentSpecifications;
  patient: Patient;
  label?: string;
  treatment?: {
    status?: string;
    stage?: string;
  };
  rejectionInfo: {
    rejectedBy: string;
    translatedReason?: string;
    reason: string;
    isPublic: boolean;
  };
  details: { metadata?: JSON };
  versions: VersionDetails[];
  value?: string;
  metadata?: JSON;
  alignerProductionInfo?: {
    deliveryObject: {
      status: string;
      statusLabel: string;
      deliveryToClinic: boolean;
    };
    productionRecord: {
      status: string;
      statusLabel: string;
      actualDate: Date;
      expectedDate: {
        from: Date;
        to: Date;
      };
      deliveryToClinic: boolean;
    };
  };
  pickUpDetails?: {
    outcome: string;
    actualDate: Date;
    expectedDate: {
      from: Date;
      to: Date;
    };
    illFittingComments: {
      concerns: string;
    };
  };
  documents?: Documents[];
}

export interface keyLinks {
  salesforceLink: string;
  adminPortalLink: string;
}

export interface Consent {
  signedOn: string;
}

export interface PatientData {
  id: string;
  updatedAt: Date;
  predecessorId: string;
  clinic: Clinic;
  stage: AllStages;
  issues: string;
  patientId: string;
  concern: string;
  patient: PatientDetail;
  status: string;
  zenyumCouncilPatientConcern: string;
  zenyumCouncilExclusion: string;
  successorId: string;
  numberOfRetainerSets: number;
  retainerPrintedUpperLowerSets: string;
  family: Family[];
  employee: Employee;
  medicalStaff: MedicalStaff;
  documents: Documents[];
  priorTreatment: PriorTreatment;
  treatmentSpecifications: TreatmentSpecifications;
  offboardingUpperLowerSets: string;
  patientOffboardingComments: string;
  internalDoctorOffboardingComments: string;
  externalDoctorOffboardingComments?: string;
  internalDoctorReview: string;
  type: string;
  details: TreatmentDetails;
  alignerProductionInfo: AlignerProductionType;
  treatmentPlan: TreatmentPlanType[] | null;
  keyLinks: keyLinks;
  treatmentAppointments: TreatmentAppointment[];
  designId: string;
  submissionReason: string;
  alignerInfo: JSON;
  refinementGoals: [JSON];
  attachments: string;
  iprInfo: JSON;
  iprInfoText: string;
  totalAlignerSet: string;
  treatmentConsent: {
    patientConsent: Consent;
    guardianConsent: Consent;
  };
  terminated?: boolean;
  relatedTreatment?: Family;
}

export interface TreatmentFamilies {
  aligner: Family[];
  retainer: Family[];
  whitening: Family[];
}

export interface SegmentInfoInput {
  fieldName: string;
  updatedAt: string;
}

interface MetadataDetails {
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  isEditable?: boolean;
  lastUpdatedUserName?: string;
}

export interface SidepanelPreference {
  isOpen: boolean;
  view: string;
}

export interface Metadata {
  offboardingUpperLowerSets: MetadataDetails;
  patientOffboardingComments: MetadataDetails;
  segmentInfo: MetadataDetails;
  updatedBy: MetadataDetails;
  prescription?: MetadataDetails;
}

interface TreatmentDetails {
  internalDoctorReview: string;
  externalDoctorReview: string;
  opportunityId: string;
  segmentInfo: {
    prescriptionLastFilled: SegmentInfoInput;
    prescriptionFirstFilled: SegmentInfoInput;
  };
  metadata: Metadata;
  consentStatus: CONSENT_FORM_STATUS;
  offboardingLink: string;
  fittingAppointmentOutcome: string;
  resubmissionRequestedAt: string;
}
interface StatusInfo {
  status: string;
  statusUpdatedAt: Date;
  statusLabel: string;
}
export interface AlignerProductionType {
  deliveryObject: StatusInfo;
  productionRecord: StatusInfo;
}

export interface TreatmentPlan {
  id: string;
  name: string;
  treatmentSpecifications: TreatmentSpecifications[];
  documents: TreatmentPlanDocument[];
}
export interface TreatmentPlanDocument {
  sourceType: string;
  documentType: string;
  presignedUrl: string;
}
export interface PatientDetails {
  issues?: string;
  concerns?: string;
  files?: FilesData[];
  zenyumCouncilPatientConcern?: string;
  zenyumCouncilExclusion?: string;
  patientId?: string;
}

// Patient Tab Filters
export interface PatientTabFilter {
  key: string;
  translationKey: string;
  order?: number;
}

export interface PatientTabSelectFilter {
  key: string;
  value: string;
}
export interface DeliveryObjectCount {
  status: string;
  count: number;
}
export interface DeliveryObjectsList {
  patientName: string;
  treatmentId: string;
}
export interface Staff {
  id: string;
  firstName: string;
  lastName: string;
}
export interface PatientListFilterData {
  getAllTreatmentTypes: Option[];
  getAllTreatmentStatuses: Option[];
  getTreatmentActions: Option[];
  getSupportedCountries: Option[];
  getAllStaff: { staff: Staff[] };
}

export interface PatientDetailsData {
  getTreatmentById: PatientData;
}

export interface TreatmentFamiliesData {
  getTreatmentFamiliesOfPatient: TreatmentFamilies;
}
export interface UnreadMessageCount {
  getUnReadMessageCount: number;
}
export interface TPVDetails {
  id: string;
  confirmedTeethExtractions: string;
  treatingDoctorComments: string;
  translatedTreatingDoctorComments: string;
  teethExtractionZenplus: string;
  feedbackToZenyum: string;
  translatedFeedbackToZenyum: string;
  details: {
    designSpecification: {
      rescanRequired: boolean;
    };
  };
}
export interface GetTPVDetail {
  getTreatmentPlanVersionById: TPVDetails;
}

export interface ResubmissionFile {
  treatmentPlan: {
    id: string;
    resubmissionFiles: string[];
  }[];
  documents: Documents[];
}
export interface ResubmissionFiles {
  getTreatmentById: ResubmissionFile;
}

export interface GetCountByView {
  getCountByView: { value?: string; count?: number };
}
export interface GetPatientDetails {
  (config?: QueryHookOptions): QueryResult<PatientDetailsData>;
}

export interface GetTreatmentFamilies {
  (config?: QueryHookOptions): QueryResult<TreatmentFamiliesData>;
}

interface TreatmentPaymentDetails {
  getTreatmentPaymentDetails: {
    opportunityId: string;
    paymentDate: Date;
    status: string;
  };
}

export interface GetTreatmentPaymentDetails {
  (config?: QueryHookOptions): QueryResult<TreatmentPaymentDetails>;
}

export interface RetainerInput {
  id: string;
  input: {
    treatmentSpecifications: {
      isRetainerEligible: boolean;
      retainerRejectionReason?: string;
      cadence?: {
        wearTimePrescribed?: boolean;
        hours?: number;
        schedule?: string;
        additionalInstructions?: string;
      };
      retainerSetInfo?: {
        upper?: number;
        lower?: number;
        type?: string;
      };
    };
    whiteningSpecifications?: WhiteningPrescriptionData;
  };
}

export enum WhiteningPrescriptionValidityEnum {
  SIX_MONTHS = 'SIX_MONTHS',
  ONE_YEAR = 'ONE_YEAR',
  TWO_YEARS = 'TWO_YEARS'
}

interface WhiteningPrescriptionData {
  cadence?: {
    wearTimePrescribed?: boolean;
    hours?: number;
    days?: number;
  };
  whiteningInfo: {
    isWhiteningEligible?: boolean;
    isValidForRepurchase?: boolean;
    validFor?: WhiteningPrescriptionValidityEnum;
  };
  whiteningRejectionReason?: string;
  whiteningRejectionReasonDescription?: string;
}
export interface SaveWhiteningPrescriptionInput {
  id: string;
  input: {
    treatmentSpecifications: WhiteningPrescriptionData;
  };
}

export enum PickUpOutcome {
  SUCCESS = 'SUCCESS',
  ILL_FITTING = 'ILL_FITTING',
  PENDING_PICK_UP = 'PENDING_PICK_UP'
}

export interface SubmitPickUpOutcomeInput {
  treatmentId: string;
  outcome: PickUpOutcome;
}

export interface SaveRetainerResponse {
  saveRetainerPrescription: {
    id: string;
    updatedAt: Date;
  };
}

export interface SaveWhiteningPrescriptionResponse {
  saveWhiteningPrescription: {
    id: string;
    updatedAt: Date;
  };
}

export interface SubmitWhiteningPrescriptionResponse {
  submitWhiteningPrescription: {
    id: string;
  };
}
export interface UpdateRetainer {
  (
    config?: MutationHookOptions<
      SaveRetainerResponse,
      RetainerInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    SaveRetainerResponse,
    RetainerInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface UpdateWhitening {
  (
    config?: MutationHookOptions<
      SaveWhiteningPrescriptionResponse,
      SaveWhiteningPrescriptionInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    SaveWhiteningPrescriptionResponse,
    SaveWhiteningPrescriptionInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface SubmitWhitening {
  (
    config?: MutationHookOptions<
      SubmitWhiteningPrescriptionResponse,
      SaveWhiteningPrescriptionInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    SubmitWhiteningPrescriptionResponse,
    SaveWhiteningPrescriptionInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface SubmitRetainer {
  (
    config?: MutationHookOptions<
      SaveRetainerResponse,
      RetainerInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    SaveRetainerResponse,
    RetainerInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface SubmitPickUpOutcome {
  (
    config?: MutationHookOptions<
      any,
      SubmitPickUpOutcomeInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    any,
    SubmitPickUpOutcomeInput,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface GetUnReadMessageCount {
  (config?: QueryHookOptions): QueryResult<UnreadMessageCount>;
}
export interface GetTPVDetails {
  (config?: QueryHookOptions): QueryResult<GetTPVDetail>;
}

export interface UpdateTpvDetail {
  updateTreatmentPlanVersion: {
    id: string;
  };
}
export interface UpdateTPVDetails {
  (config?: MutationHookOptions): MutationTuple<
    UpdateTpvDetail,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface ReOpenTpvDetail {
  reOpenTreatmentPlanVersion: {
    id: string;
  };
}
export interface ReOpenTpvDetails {
  (config?: MutationHookOptions): MutationTuple<
    ReOpenTpvDetail,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface GetReSubmissionFiles {
  (config?: QueryHookOptions): QueryResult<ResubmissionFiles>;
}

export interface GetPatientDocuments {
  (config?: QueryHookOptions): QueryResult<{
    getTreatmentDocumentByTreatmentIds: Documents[];
  }>;
}
export interface GetPatientDocument {
  (config?: QueryHookOptions): QueryResult<{
    getTreatmentDocumentByTreatmentId: Documents[];
  }>;
}

export type GetOpportunityData = {
  getTreatmentById: { details: { opportunityId: string } };
};
export interface DesignLabs {
  id: string;
  treatmentPlanningLab: string;
  manufacturer: string;
  isDefault: boolean;
}
export type GetDesignLabs = {
  getDesignLabs: DesignLabs[];
};

export enum PATIENT_LIST_FILTERS {
  TYPE = 'treatmentType',
  STATUS = 'status',
  RETAINER_STATUS = 'retainerStatus',
  WHITENING_STATUS = 'whiteningStatus',
  REFERRAL = 'referral',
  SORT = 'sort',
  BRANCHES = 'branches',
  COUNTRY = 'country',
  COUNCIL = 'council',
  PLAN_TYPE = 'planType',
  DOCTOR = 'doctor',
  TREATMENT = 'type',
  ACTION_REQUIRED = 'actionRequired',
  WHITENING_ACTIONS = 'whiteningActions'
}

export type PatientFilterValues = {
  [PATIENT_LIST_FILTERS.TYPE]: Option[];
  [PATIENT_LIST_FILTERS.STATUS]: Option[];
  [PATIENT_LIST_FILTERS.RETAINER_STATUS]: Option[];
  [PATIENT_LIST_FILTERS.WHITENING_STATUS]: Option[];
  [PATIENT_LIST_FILTERS.REFERRAL]: Option[];
  [PATIENT_LIST_FILTERS.SORT]: Option;
  [PATIENT_LIST_FILTERS.BRANCHES]: Option[];
  [PATIENT_LIST_FILTERS.DOCTOR]: Option[];
  [PATIENT_LIST_FILTERS.COUNTRY]: Option[];
  [PATIENT_LIST_FILTERS.COUNCIL]?: Option[];
  [PATIENT_LIST_FILTERS.PLAN_TYPE]?: Option[];
  [PATIENT_LIST_FILTERS.TREATMENT]?: Option[];
  [PATIENT_LIST_FILTERS.ACTION_REQUIRED]?: Option[];
  [PATIENT_LIST_FILTERS.WHITENING_ACTIONS]?: Option[];
};

export type PatientListParams = PatientFilterValues & {
  patientFilter?: AutoSuggestions;
  selectedTab?: string;
};

export type FilterConfig = {
  testId: string;
  options: Option[];
  name: PATIENT_LIST_FILTERS;
  hidden?: boolean;
  label: string;
}[];

export interface GiveInstructionsFilterValues {
  type: Option[];
  status: Option[];
  branch: Option[];
  doctor: Option[];
  country: Option[];
  council: Option[] | undefined;
  [index: string]: any;
}

export interface PatientListSort {
  sortBy: string;
  sortOrder: string;
}

export interface GetTreatmentFamilyDetails {
  getTreatmentById: {
    family: Family[];
  };
}

export interface GetTreatmentFamily {
  (config?: QueryHookOptions): QueryResult<GetTreatmentFamilyDetails>;
}

export type TabList = {
  key: string;
  translationKey: string;
  order: number;
};
export type Action = {
  key: string;
  value: string;
};

export type MainTabs = {
  aligner: TabList[];
  retainer: TabList[];
  whitening: TabList[];
};

export type getTreatmentListViews = {
  getTreatmentListViews: MainTabs;
};
export type getAllTreatmentStatuses = {
  getAllTreatmentStatuses: PatientTabSelectFilter[];
};
export type getDeliveryObjectsCount = {
  getDeliveryObjectsCount: DeliveryObjectCount[];
};
export type getDeliveryObjectsList = {
  getDeliveryObjectsList: DeliveryObjectsList[];
};
export type getTreatmentActions = {
  getTreatmentActions: Action[];
};
export type UpdatePatientType = {
  updatePatient: boolean;
};
export type ReassignCouncilFormType = {
  council: Option;
};

export type SecondaryTreatmentTypes = 'RETAINER' | 'WHITENING';
