import { FC, useState, useMemo } from 'react';

import cx from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ChipLabel, NameCard, RemoveTeamMember } from 'components';
import { INBOX_GROUP_NAME, TEAM_NAME, TICKET_STATUS } from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import { useRemoveUserFromTicket } from 'services/hooks/Inbox.hooks';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { loginedUserDetails, selectedTicket } from 'store/atoms';
import { Participant, UserDetails } from 'types/Inbox.types';
import { isDataExist, translate } from 'utils';
import { getFullName, getParticipantCount, getTeamName } from 'utils/common';
import { useSetError } from 'utils/customHooks/useSetError';
import {
  getFilteredParticipantsList,
  getSegmentUserDetails
} from 'utils/inbox';

interface Props {
  participantsList: Participant[];
  isFromTicketDetails?: boolean;
  setParticipantsList?: (participants: Participant[]) => void;
  isTPCouncil: boolean;
}

const UserList: FC<Props> = ({
  participantsList,
  isFromTicketDetails = false,
  setParticipantsList,
  isTPCouncil
}) => {
  const userDetails = useRecoilValue(loginedUserDetails);
  const handleSegmentTrack = useSegmentTrackChat();
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useRecoilState(selectedTicket);
  const { trackData, trackContextData, trackPatientData } = useTrackData();
  const { setError } = useSetError();

  const [isOwnerAndInitatorSame, setIsOwnerAndInitatorSame] =
    useState<boolean>(false);
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();

  const [removeUser, { loading: isRemovalLoading }] = useRemoveUserFromTicket({
    onCompleted: (response: UserDetails) => {
      let message;
      if (
        selectedParticipant?.userFirstName ||
        selectedParticipant?.userLastName
      ) {
        message = translate('INBOX.REMOVED_FROM', {
          X: getFullName({
            firstName: selectedParticipant?.userFirstName || '',
            lastName: selectedParticipant?.userLastName || '',
            salutation: ''
          })
        });
      }
      if (response.removeUserFromTicket.userId !== userDetails.id) {
        const selectedTicket = { ...selectedTicketDetails };
        selectedTicket.ticketUsers = selectedTicket.ticketUsers.filter(
          (user) => user.userId !== response.removeUserFromTicket.userId
        );
        setSelectedTicketDetails(selectedTicket);
      }
      setSelectedParticipant(undefined);
      message && setError(message, true, 'z-1000');
    }
  });

  const formatParticipantName = (participent: Participant | undefined) => {
    return (
      <div className='flex flex-row flex-wrap'>
        {isTPCouncil &&
        participent?.userTeamCode === TEAM_NAME.EXTERNAL_DOCTOR.key ? (
          <span>{getTeamName(participent?.userTeamCode || '-')}</span>
        ) : (
          <>
            {' '}
            <span className='text-sm'>
              {getFullName({
                firstName: participent?.userFirstName,
                lastName: participent?.userLastName,
                salutation: ''
              })}
              &nbsp;
            </span>
            {userDetails.id === participent?.userId ? (
              <span className='text-DEFAULT_TEXT font-bold'>
                ({translate('INBOX.YOU')})
              </span>
            ) : (
              participent?.userTeamCode && (
                <span>({getTeamName(participent?.userTeamCode || '-')})</span>
              )
            )}
          </>
        )}
      </div>
    );
  };
  const [showModal, setShowModal] = useState(false);
  const handleRemove = (participent: Participant) => {
    const {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission
    } = trackData;
    const {
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type
    } = trackContextData;
    handleSegmentTrack(CHAT_TRACK_EVENTS.PARTICIPANT_REMOVAL_INITIATED, {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission,
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type,
      ...trackPatientData,
      is_chat_created: isFromTicketDetails ? 'yes' : 'no',
      no_of_participants: formatedParticipantList.length
    });
    setSelectedParticipant(participent);
    setShowModal(true);
  };

  const handleConfirmClose = () => {
    const {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission
    } = trackData;
    const {
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type
    } = trackContextData;
    handleSegmentTrack(CHAT_TRACK_EVENTS.PARTICIPANT_REMOVED, {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission,
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type,
      no_of_participants: formatedParticipantList.length - 1,
      is_chat_created: isFromTicketDetails ? 'yes' : 'no'
    });
    const updatedParticipantsList = participantsList.filter(
      (participant) => participant.userId !== selectedParticipant?.userId
    );
    if (isFromTicketDetails && !isRemovalLoading) {
      const param = {
        variables: {
          userId: selectedParticipant?.userId,
          ticketId: selectedTicketDetails.id
        }
      };
      const zenchatUpdateUserBqData = {
        zenchat_ID: trackData.zenchat_ID,
        opportunity_name: trackPatientData.opportunity_name,
        patient_name: trackPatientData.patient_name,
        patient_country: trackPatientData.patient_country,
        zenchat_status: trackData.zenchat_status,
        zenchat_urgent: trackData.zenchat_urgent,
        zenchat_topic: trackData.zenchat_topic,
        chat_created_ts: trackData.chat_created_ts,
        no_of_participants: getParticipantCount(updatedParticipantsList),
        ...getSegmentUserDetails(updatedParticipantsList)
      };
      handleSegmentTrack(
        CHAT_TRACK_EVENTS.ZENCHAT_USER_UPDATE_BQ,
        zenchatUpdateUserBqData
      );
      removeUser(param);
    } else {
      setParticipantsList?.(updatedParticipantsList);
    }
    setShowModal(false);
  };

  const formatedParticipantList = useMemo(() => {
    return getFilteredParticipantsList(
      participantsList,
      setIsOwnerAndInitatorSame
    );
  }, [participantsList]);

  const checkShowInitator = (participant: Participant) => {
    if (
      participant.groupName === INBOX_GROUP_NAME.CHAT_INITIATOR ||
      (participant.groupName === INBOX_GROUP_NAME.CHAT_OWNER &&
        isOwnerAndInitatorSame)
    ) {
      return true;
    }
    return false;
  };

  const checkShowRemove = (participent: Participant) =>
    participent.groupName !== INBOX_GROUP_NAME.CHAT_INITIATOR &&
    participent.groupName !== INBOX_GROUP_NAME.CHAT_OWNER &&
    (selectedTicketDetails.isUserPartOfTicket || !isFromTicketDetails) &&
    (!isFromTicketDetails ||
      selectedTicketDetails.status !== TICKET_STATUS.CLOSE);

  return (
    <div className='xs:mt-1'>
      <RemoveTeamMember
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleConfirmClose}
        name={formatParticipantName(selectedParticipant)}
        isLoginedUser={userDetails.id === selectedParticipant?.userId}
      />
      {isDataExist(participantsList) &&
        formatedParticipantList.map((participent, index) => (
          <div
            key={`${participent.userId}_${index}`}
            className='flex w-full items-center justify-between '
          >
            <div
              className={cx('flex  items-center', {
                ' w-full justify-between': true
              })}
            >
              <NameCard
                name={formatParticipantName(participent)}
                className='text-sm min-w-min flex-col'
                image={participent?.userProfilePicture}
                isDeactivated={!!participent?.deactivatedAt}
              />
              <div className='flex flex-col items-end'>
                {checkShowInitator(participent) && (
                  <ChipLabel className='bg-YELLOW text-BROWN text-sm mt-0 mb-1 flex justify-center min-w-80px'>
                    {translate('descriptive.initiator')}
                  </ChipLabel>
                )}
                {participent.groupName === INBOX_GROUP_NAME.CHAT_OWNER && (
                  <ChipLabel className='bg-LIGHT_GREEN text-SUCCESS text-sm mt-0 flex justify-center min-w-80px'>
                    {translate('descriptive.owner')}
                  </ChipLabel>
                )}
              </div>
            </div>
            {checkShowRemove(participent) && (
              <button
                className='text-PRIMARY text-sm underline mr-1 disabled:text-DISABLED'
                onClick={() => handleRemove(participent)}
                type='button'
                disabled={isRemovalLoading}
              >
                {translate('actions.remove')}
              </button>
            )}
          </div>
        ))}
    </div>
  );
};

export default UserList;
